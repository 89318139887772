import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

// third-party
import ReactECharts from "echarts-for-react";
import { observe } from "mobx";
import { observer } from "mobx-react-lite";
import ChangesLabel from "../changes-label";

const DashbaordBarChart = ({
  series = [], height = "250px",
}: {
  series: any[], height?: string,
}) => {

  // const [chartRef, setChartRef] = useState(null);

  // const sum = series.reduce((a, b) => a + b.value, 0);

  const option = {
    xAxis: {
      type: 'category',
      data: series.map((item: any) => item.title),
      width: 30,
    },
    yAxis: {
      type: 'value',
      label: {
        formatter: '{value}'
      }
    },
    series: [
      {
        data: series.map((item: any) => item.value),
        type: 'bar',
        barWidth: '40%',
        label: {
          show: true,
          position: 'top',
          formatter: (anything: any) => {
            return `${series[anything.dataIndex].value.toLocaleString()}`;
            // return `${series[anything.dataIndex].value.toLocaleString()}\n${series[anything.dataIndex].change}`;
            // return <p>
            //   <p>{series[anything.dataIndex].value}</p>
            //   <ChangesLabel changeAmount={series[anything.dataIndex].change} />
            // </p>
          },
        }
      }
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: (params: any) => {
        return params.map((item: any) => `${item.name}: ${item.value.toLocaleString()}`).join('<br/>');
      }
    },
    color: ["#0061ff", "#1d2f53", "#15f5ba"],
    grid: {
      top: 15,
      left: 0,
      right: 0,
      bottom: 0,
      containLabel: true
    },
  };

  // useEffect(() => {
  //   if (!chartRef) return;

  //   chartRef.on("click", onClickPieItem);
  // }, [chartRef]);

  // useEffect(() => {
  //   // THIS FUNCTION REPLACE THE CHART ONCLICK FUNCTION, WHENEVER THAT WAS UPDATED.
  //   const chart = chartRef;
  //   if (!chart) return;
  //   chart.off("click", chart._$handlers.click[0].h);
  //   chart.on("click", onClickPieItem);
  // }, [onClickPieItem]);

  return (
    <Box className="w-full flex flex-col">
      <ReactECharts
        option={option}
        style={{ width: "100%", height: height }}
        className="flex-1"
      // onChartReady={(chart) => {
      //   setChartRef(chart);
      //   chart.on("click", onClickPieItem);
      // }}
      />
    </Box >
  );
}

export default observer(DashbaordBarChart);
