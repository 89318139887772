import { createTheme } from '@mui/material/styles';

// Define your custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // Blue
        },
        secondary: {
            main: '#ff4081', // Pink
        },
        background: {
            default: '#f4f6f8',
        },
        text: {
            primary: '#333333',
            secondary: '#555555',
        },
    },
    typography: {
        fontFamily: 'Figtree, Arial, sans-serif', // Google font example
        h1: {
            fontSize: '2.125rem',
            fontWeight: 500,
        },
        body1: {
            fontSize: '1rem',
        },
    },
    shape: {
        borderRadius: 8, // Rounded corners globally
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '20px', // Rounded corners for buttons
                    textTransform: 'none', // Remove uppercase transformation
                },
            },
        },
    },
});

export default theme;
