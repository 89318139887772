import { makeAutoObservable } from 'mobx';
import { kLoggingInEmail } from '../config/constants';

class SecureStorage {
    private static instance: SecureStorage;
    jwtToken: string | null = null;
    items: Record<string, any> = {};

    private kLoggingInEmail = 'kLoggingInEmail'; // Add constant for the key

    constructor() {
        if (SecureStorage.instance) {
            return SecureStorage.instance;
        }
        makeAutoObservable(this); // MobX tracks observables and actions
        SecureStorage.instance = this;
    }

    // Get the singleton instance
    public static getInstance(): SecureStorage {
        if (!SecureStorage.instance) {
            SecureStorage.instance = new SecureStorage();
        }
        return SecureStorage.instance;
    }

    // Method to retrieve the JWT token (in-memory)
    public getJwtToken(): string | null {
        return this.jwtToken;
    }

    // Method to set the JWT token (in-memory)
    public setJwtToken(token: string): void {
        this.jwtToken = token;
    }

    // Clear the JWT token (e.g., on logout)
    public clearJwtToken(): void {
        this.jwtToken = null;
    }

    // Use localStorage for kLoggingInEmail
    public setItem(key: string, value: any): void {
        if (key === kLoggingInEmail) {
            localStorage.setItem(key, value); // Store email in localStorage
        } else {
            this.items[key] = value;
        }
    }

    public getItem(key: string): any {
        if (key === kLoggingInEmail) {
            return localStorage.getItem(key); // Retrieve email from localStorage
        }
        return this.items[key] || null;
    }


    // Remove an item, with localStorage for special keys
    public removeItem(key: string): void {
        if (key === kLoggingInEmail) {
            // Remove from localStorage for kLoggingInEmail
            localStorage.removeItem(key);
        } else {
            // Remove from in-memory storage for other keys
            delete this.items[key];
        }
    }

    // Clear all stored data (in-memory and localStorage)
    public clear(): void {
        this.jwtToken = null;
        this.items = {};
        localStorage.removeItem(kLoggingInEmail); // Remove specific key from localStorage
    }
}

export default SecureStorage;
