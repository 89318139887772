import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import Layout from '../layouts/Layout';
import FilterSection, { FilterLevel, FilterLevelOne, FilterLevelThree, FilterLevelTwo } from '../components/summary/filter-section';
import { observer } from 'mobx-react-lite';
import theme from '../styles/theme';
import VmCheckBox from '../components/VmCheckBox';
import { ArrowForwardIos, ArrowUpward, Close } from '@mui/icons-material';
import { GenerateCompareToYearOptions, GenerateFYOptions } from '../utils/general';
import moment from 'moment';
import VmDropdown from '../components/VmDropdown';
import VmModal from '../components/VmModal';
import SectorCompareBarChart from '../components/charts/sector-compare-bar-chart';
import RegionDataTable from '../components/charts/region-data-table';
import SingleBarChart from '../components/charts/single-bar-chart';
import ScopeCompareBarChart from '../components/charts/scope-compare-bar-chart';
import LineChartWithMarkings from '../components/charts/line-chart-with-markings';
import CriteriaHeader from '../layouts/criteria-header';
import SummeryAEScopeSection from '../components/summary-page-sections/ae/ae_emissions_scope';
import SummeryAESectorSection from '../components/summary-page-sections/ae/ae_emissions_sector';
import SummeryAENonBioEmissionIntensityByScope from '../components/summary-page-sections/ae/non-bio-emission';
import SummaryAEIntensityByFarm from '../components/summary-page-sections/ae/intensity-by-farm';
import SummeryAERegionSection from '../components/summary-page-sections/ae/ae_emissions_region';
import SummaryLUSummarySection from '../components/summary-page-sections/lu_removals/lu_summary';
import SummaryLULandUseTypeSection from '../components/summary-page-sections/lu_removals/lu_landusetype';
import SummaryRemovalsSummarySection from '../components/summary-page-sections/lu_removals/removal_summary';
import SummaryCarbonStockTypeSection from '../components/summary-page-sections/lu_removals/removal_carbon_stock_type';

import { useSearchParams } from 'react-router-dom';
import SummaryLUMapSection from '../components/summary-page-sections/lu_removals/lu_map';
import SummaryRemovalsMapSection from '../components/summary-page-sections/lu_removals/removal_map';
import SummeryFEScopeSection from '../components/summary-page-sections/fe/fe_emission_scope_section';
import SummeryFESectorSection from '../components/summary-page-sections/fe/fe_emission_sector_section';
import SummaryFERegionSection from '../components/summary-page-sections/fe/fe_emission_region_section';
import SummaryPhysicalEEISection from '../components/summary-page-sections/fe/fe_physical_eei_section';
import SummaryPhysicalPEISection from '../components/summary-page-sections/fe/fe_physical_pei_section';
import SummaryPhysicalWACISection from '../components/summary-page-sections/fe/fe_physical_waci_section';
import SummaryGHGScopeSection from '../components/summary-page-sections/ae/ae_emissions_ghg_types';

// const FinancialPeriods = GenerateFYOptions();
// const CompareToYearOptions = GenerateCompareToYearOptions();

const SummaryPage: React.FC = () => {
  const [filterLevelOneId, setFilterLevelOneId] = useState<number>(FilterLevelOne.AE);
  const [filterLevelTwoId, setFilterLevelTwoId] = useState<number>(0);
  const [filterLevelThreeId, setFilterLevelThreeId] = useState<number>(0);

  // // Search Params
  // const [financialYear, setFinancialYear] = useState<number>(moment().year() - 1);
  // const [compareToYear, setCompareToYear] = useState<number>(CompareToYearOptions[0].value);

  const [openClimateChangeModal, setOpenClimateChangeModal] = useState<boolean>(false);

  const handleFilterChange = ({ levelOneId, levelTwoId, levelThreeId }: { levelOneId: number, levelTwoId: number, levelThreeId: number }) => {
    setFilterLevelOneId(levelOneId);
    setFilterLevelTwoId(levelTwoId);
    setFilterLevelThreeId(levelThreeId);
  };

  return (
    <Layout onTriggerAfterSearch={(search: string) => { console.log(search) }} >

      <CriteriaHeader
        breadCrumb={
          filterLevelOneId === FilterLevelOne.AE ? "Summary / Absolute Emissions" :
            filterLevelOneId === FilterLevelOne.FINANCED ? "Summary / Financial Emissions" :
              filterLevelOneId === FilterLevelOne.LAND_USE_AND_REMOVALS ? "Summary / Land Use & Carbon Removals" :
                filterLevelOneId === FilterLevelOne.PHYSICAL_RISK ? "Summary / Physical Risk" :
                  "Summary"
        }
      />

      <FilterSection callback={handleFilterChange} />

      <Box className="mt-4">

        {/* AE SECTION */}
        {
          filterLevelOneId === FilterLevelOne.AE && <>

            {/* AE => EMISSIONS */}
            {
              filterLevelTwoId === FilterLevelTwo.AE_EMISSIONS && <>
                {
                  filterLevelThreeId === FilterLevelThree.AE_EMISSION_EMISSION_SUMMARY && <>
                    <SummeryAEScopeSection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.AE_EMISSION_EMISSION_GHG_TYPES && <>
                    <SummaryGHGScopeSection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.AE_EMISSION_SECTOR && <>
                    <SummeryAESectorSection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.AE_EMISSION_REGION && <>
                    <SummeryAERegionSection />
                  </>
                }
              </>
            }

            {/* AE => PHYSICAL */}
            {
              filterLevelTwoId === FilterLevelTwo.AE_PHYSICAL && <>
                {
                  filterLevelThreeId === FilterLevelThree.AE_PHYSICAL_EMISSION_PER_HA_PER_FARM && <>
                    <SummaryAEIntensityByFarm />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.AE_PHYSICAL_NON_BIO_EMISSION && <>
                    <SummeryAENonBioEmissionIntensityByScope />
                  </>
                }
              </>
            }
          </>
        }


        {/* FE SECTION */}
        {
          filterLevelOneId === FilterLevelOne.FINANCED && <>

            {/* FINANCED => EMISSIONS */}
            {
              filterLevelTwoId === FilterLevelTwo.FINANCED_EMISSIONS && <>
                {
                  filterLevelThreeId === FilterLevelThree.FINANCED_EMISSION_SCOPE && <>
                    <SummeryFEScopeSection />
                    <p className="opacity-35">PENDING CONNECT REAL DATA</p>
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.FINANCED_EMISSION_SECTOR && <>
                    <SummeryFESectorSection />
                    <p className="opacity-35">PENDING CONNECT REAL DATA</p>
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.FINANCED_EMISSION_REGION && <>
                    <SummaryFERegionSection />
                    <p className="opacity-35">PENDING CONNECT REAL DATA</p>
                  </>
                }
              </>
            }

            {/* FINANCED => PHYSICAL */}
            {
              filterLevelTwoId === FilterLevelTwo.FINANCED_PHYSICAL && <>
                {
                  filterLevelThreeId === FilterLevelThree.FINANCED_PHYSICAL_EEI && <>
                    <SummaryPhysicalEEISection />
                    <p className="opacity-35">PENDING CONNECT REAL DATA</p>
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.FINANCED_PHYSICAL_PEI && <>
                    <SummaryPhysicalPEISection />
                    <p className="opacity-35">PENDING CONNECT REAL DATA</p>
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.FINANCED_PHYSICAL_WACI && <>
                    <SummaryPhysicalWACISection />
                    <p className="opacity-35">PENDING CONNECT REAL DATA</p>
                  </>
                }
              </>
            }
          </>
        }

        {/* LAND USE AND REMOVALS SECTION */}
        {
          filterLevelOneId === FilterLevelOne.LAND_USE_AND_REMOVALS && <>

            {/* LAND USE AND REMOVALS => LAND USE */}
            {
              filterLevelTwoId === FilterLevelTwo.LAND_USE_LAND_USE && <>
                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_SUMMARY && <>
                    <SummaryLUSummarySection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_MAP && <>
                    <SummaryLUMapSection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_LAND_USE_TYPE && <>
                    <SummaryLULandUseTypeSection />
                  </>
                }
              </>
            }

            {/* LAND USE AND REMOVALS => REMOVALS */}
            {
              filterLevelTwoId === FilterLevelTwo.LAND_USE_LAND_REMOVALS && <>
                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_REMOVALS_SUMMARY && <>
                    <SummaryRemovalsSummarySection />
                  </>
                }
                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_REMOVALS_MAP && <>
                    <SummaryRemovalsMapSection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE && <>
                    <SummaryCarbonStockTypeSection />
                  </>
                }
              </>
            }
          </>
        }

        {/* PHYSICAL RISK SECTION */}
        {
          filterLevelOneId === FilterLevelOne.PHYSICAL_RISK && <>

            {
              filterLevelTwoId === FilterLevelTwo.PHYSICAL_RISK_ACUTE && <>
                {
                  filterLevelThreeId === FilterLevelThree.PHYSICAL_RISK_ACUTE_DROUGHT && <>
                    <SingleBarChart />
                  </>
                }
                {
                  filterLevelThreeId === FilterLevelThree.PHYSICAL_RISK_ACUTE_FLOOD && <>
                    <SingleBarChart />
                  </>
                }
              </>
            }

            {
              filterLevelTwoId === FilterLevelTwo.PHYSICAL_RISK_CHRONIC && <>
                {
                  filterLevelThreeId === FilterLevelThree.PHYSICAL_RISK_CHRONIC_TEMPERATURE && <>
                    <LineChartWithMarkings />
                  </>
                }
                {
                  filterLevelThreeId === FilterLevelThree.PHYSICAL_RISK_CHRONIC_RAINFALL && <>
                    <LineChartWithMarkings />
                  </>
                }
              </>
            }

          </>
        }
      </Box>


      <VmModal open={openClimateChangeModal} onClose={() => setOpenClimateChangeModal(false)} >
        <Box className="flex flex-col gap-4">
          <Box className="flex justify-between items-center">
            <Typography variant="h5">Climate Change - Scope</Typography>
            <IconButton onClick={() => setOpenClimateChangeModal(false)}>
              <Close />
            </IconButton>
          </Box>

          {/* Switch between disorderly and hothouse */}
          <Box className="flex gap-2 items-center">
            <Button variant="outlined" sx={{ borderRadius: '4px' }}>1.5 (RCP2.6 /NGFS Disorderly-Delayed)</Button>
            <Button variant="outlined" sx={{ borderRadius: '4px', color: "black", borderColor: "black", opacity: 0.3 }} >2.5 (RCP4.5/NGFS Hothouse-Current)</Button>
          </Box>

          <p>There will be a chart almost identical to the one outside.</p>
        </Box>
      </VmModal>
    </Layout >
  );
};

export default observer(SummaryPage);
