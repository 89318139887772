import { Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import theme from '../styles/theme'

const VmDropdown = ({ sx, label, value, onChange, options, disabled }: { sx?: any, label: string, value: any, onChange: (value: any) => void, options: { value: string, label: string }[] | any[], disabled?: boolean }) => {
  return (
    <TextField select value={value} label={label} size='small' onChange={(e: any) => onChange(e.target.value)} sx={{ ".MuiSelect-select": { paddingY: 0.5 }, ".MuiInputBase-root": { borderRadius: '4px' }, backgroundColor: 'white', ...sx }} disabled={disabled}>
      {
        options.map((option: any, i: number) => (
          <MenuItem key={`financial_period_${i}`} value={option.value}>{option.label}</MenuItem>
        ))
      }
    </TextField>
  )
}

export default observer(VmDropdown)
