import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData, getOrganizationEmissionFarmListData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import ScopeCompareBarChart from '../../charts/scope-compare-bar-chart'
import SingleBarChart from '../../charts/single-bar-chart'
import CompareFilters from '../../CompareFilters'
import { GenerateFYOptions } from '../../../utils/general'
import VmModal from '../../VmModal'
import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { Map } from '@mui/icons-material'
import { VmSmartTable, VmTBodyType } from '../../VmTables'

enum ModalMode {
  LIST = "list",
  MAP = "map"
}

const SummaryAEIntensityByFarm = observer(({ }: {}) => {
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])

  const financialPeriods = GenerateFYOptions();

  const [reportPeriods, setReportPeriods] = useState<number>(financialPeriods[0].value)
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)

  const [showFarmListModal, setShowFarmListModal] = useState<boolean>(false)
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | null>(null)

  const [farmListModalTotalCount, setFarmListModalTotalCount] = useState<number>(0)
  const [farmListModalData, setFarmListModalData] = useState<any>([])
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.LIST)

  const decileTitles = ["Low", "Medium", "High"]

  useEffect(() => {
    // console.log("summery ae scope section")
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriods,
      entityId: organizationId,
      sectors: [],
      regions: []
    }, "organization", "physical", "emissions_intensity_per_hectare_by_farm").then((data) => {
      setChartData(data.data)
    })
  }, [])

  useEffect(() => {
    if (selectedBarIndex !== null && showFarmListModal) {
      const barInfo = chartData.data[selectedBarIndex]

      getOrganizationEmissionFarmListData({
        force_refresh: false,
        include_trajectory: false,
        climateScenario: ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriods,
        entityId: organizationId,
        sectors: 'all',
        regions: 'all',
        rangeStart: barInfo.rangeStart,
        rangeEnd: barInfo.rangeEnd
      }, "organization").then((data) => {
        setFarmListModalData(data.data.items)
        setFarmListModalTotalCount(data.data.totalCount)
      }).catch((error) => {
        console.log(error)
      })
    }
  }, [selectedBarIndex, showFarmListModal])

  const handleClickBar = (clickedBinIndex: number) => {
    if (clickedBinIndex !== -1 && clickedBinIndex !== selectedBarIndex) {
      setSelectedBarIndex(clickedBinIndex)
      setShowFarmListModal(true)
    }
  }

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
      />
      <SingleBarChart data={chartData} onClickBar={handleClickBar} />

      <VmModal open={showFarmListModal} onClose={() => setShowFarmListModal(false)}>
        <Box className='flex flex-col gap-4'>
          <Typography variant='h6'>Emissions Intensity per Hectare by Farm - {selectedBarIndex !== null ? `(${decileTitles[selectedBarIndex]} ${chartData.data[selectedBarIndex].rangeStart}-${chartData.data[selectedBarIndex].rangeEnd})` : ""}</Typography>

          <Box className='flex justify-between items-center'>
            <Box className='flex gap-2 items-center'>
              <p className='text-sm'>Number of Farms</p>
              <span className='font-bold text-[1.6rem]'>{farmListModalTotalCount}</span>
            </Box>

            <div>
              <Button variant={modalMode === ModalMode.LIST ? "contained" : "outlined"} sx={{ borderRadius: "0.5rem 0 0 0.5rem" }} onClick={() => setModalMode(ModalMode.LIST)}>
                Table
              </Button>
              <Button variant={modalMode === ModalMode.MAP ? "contained" : "outlined"} sx={{ borderRadius: "0 0.5rem 0.5rem 0", borderLeft: "none" }} onClick={() => setModalMode(ModalMode.MAP)}>
                Map
              </Button>
            </div>
          </Box>

          {
            modalMode === ModalMode.LIST
              ? (
                <div>
                  <VmSmartTable
                    source={farmListModalData}
                    thead={["Asset Name / ID", "Asset Owner", "Region", "Sector/System", "Area(ha)", "Livestock production", "Crop production", "Data Quality Score"]}
                    tbody={[
                      { type: VmTBodyType.TEXT, fields: ["assetName"] },
                      { type: VmTBodyType.TEXT, fields: ["assetOwner"] },
                      { type: VmTBodyType.TEXT, fields: ["region"] },
                      { type: VmTBodyType.TEXT, fields: ["sector"] },
                      { type: VmTBodyType.NUMBER, fields: ["area"] },
                      { type: VmTBodyType.NUMBER, fields: ["livestockProduction"] },
                      { type: VmTBodyType.NUMBER, fields: ["cropProduction"] },
                      { type: VmTBodyType.NUMBER, fields: ["dataQualityScore"] },
                    ]} enableLoadMore={false} />
                </div>
              ) : (
                <div>
                  <h1>Map</h1>
                </div>
              )
          }
        </Box>


      </VmModal>
    </>
  )
})

export default SummaryAEIntensityByFarm
