import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Button, useTheme } from '@mui/material'
import { ArrowForwardIos, ArrowUpward, FileUploadOutlined } from '@mui/icons-material'
import VmDropdown from './VmDropdown'
import VmCheckBox from './VmCheckBox'
import { GenerateCompareToYearOptions, GenerateFYOptions } from '../utils/general';
import { useContext } from 'react';
import { FilterContext } from '../context/filter-bar-context';

const CompareFilters = observer(({ handleClickClimateChange, changeReportPeriodCallBack, onClickExport }: { handleClickClimateChange: () => void, changeReportPeriodCallBack?: (value: number) => void, onClickExport?: () => void }) => {
  const { reportPeriod, setReportPeriod, reportPeriodOptions, setReportPeriodOptions, compareToYears, setCompareToYears, compareToFuture, setCompareToFuture, showBenchmark, setShowBenchmark, showTarget, setShowTarget, allowedCompareToYears, setAllowedCompareToYears } = useContext(FilterContext)
  const compareToYearOptions = GenerateCompareToYearOptions(allowedCompareToYears, false);
  const compareToFutureOptions = GenerateCompareToYearOptions(allowedCompareToYears, true);
  const theme = useTheme();

  useEffect(() => {
    if (allowedCompareToYears > 0) {
      setCompareToFuture(compareToFutureOptions[compareToFutureOptions.length - 1].value)
      setCompareToYears(compareToYearOptions[compareToYearOptions.length - 1].value)
    }
  }, [allowedCompareToYears])

  const handleChangeReportPeriod = (value: number) => {
    changeReportPeriodCallBack && changeReportPeriodCallBack(value)
    setReportPeriod(value)
  }

  return (
    <Box className="flex flex-col gap-2">
      {/* TOP ROW */}
      <Box className="flex gap-4">
        <Box className="border rounded-md p-4 py-2 bg-gray-100 flex gap-4 items-center flex-1">
          <p className='font-bold'>Current</p>
          <p>Compare to</p>

          <VmCheckBox label={"Benchmark"} checked={showBenchmark || false} onChange={(checked: boolean) => setShowBenchmark && setShowBenchmark(checked)} />
          <VmCheckBox label={"Target"} checked={showTarget || false} onChange={(checked: boolean) => setShowTarget && setShowTarget(checked)} />

          <Box className="bg-white rounded-sm p-2 py-0 flex gap-4 items-center text-gray-500">
            <Box className="flex gap-2 items-center">
              <Box className="bg-[#c7ffb6] w-3 h-3 rounded-sm"></Box>
              <p>Reach</p>
            </Box>

            <Box className="flex gap-2 items-center">
              <Box className="bg-[#ffc0c0] w-3 h-3 rounded-sm"></Box>
              <p>Not reach</p>
            </Box>
          </Box>
        </Box>

        <Button variant="outlined" sx={{ borderRadius: '8px' }} onClick={handleClickClimateChange}>
          Climate Change
          <ArrowForwardIos sx={{ fontSize: 16, ml: 1, mr: -0.5 }} />
        </Button>
      </Box>

      {/* BOTTOM ROW */}
      <Box className="border rounded-md p-4 py-2 bg-gray-100 flex justify-between ">
        <Box className="flex gap-4 items-center">
          <p className='font-bold'>Report Period</p>
          <VmDropdown label="" value={reportPeriod} onChange={handleChangeReportPeriod} options={reportPeriodOptions} />
          <p className=''>Compare to</p>
          <VmDropdown disabled={allowedCompareToYears <= 0} label="" value={compareToYears} onChange={setCompareToYears} options={compareToYearOptions} />
          {/* <p className=''>& Future</p>
        <VmDropdown label="" value={compareToFuture} onChange={setCompareToFuture} options={compareToFutureOptions} /> */}

          {/* <p>Turn out</p>
        <Box className="flex gap-1 items-center bg-[#ebf1fd] px-2 py-1 rounded-md">
          <p>xx%</p>
          <ArrowUpward sx={{ fontSize: 20 }} color='error' />
        </Box> */}
        </Box>

        <Box>
          {onClickExport && <Button variant="outlined" sx={{ borderRadius: '6px', backgroundColor: theme.palette.primary.main, color: "white" }} onClick={onClickExport}>
            <FileUploadOutlined sx={{ fontSize: 24, mr: 1 }} />
            <p>Export</p>
          </Button>}
        </Box>
      </Box>
    </Box>
  )
})

export default CompareFilters
