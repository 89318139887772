import React, { useContext, useEffect, useState } from 'react'
import RegionDataTable from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import moment from 'moment'
import { FormatKeyToLabel, GenerateFYOptions } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'
import VmModal from '../../VmModal'
import { Box } from '@mui/material'


const SummeryAEScopeSection = ({ }: {}) => {
  const { reportPeriod, compareToYears, compareToFuture, setCompareToYears, setAllowedCompareToYears, searchType, selectedRegions } = useContext(FilterContext)

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])

  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [dataKeys, setDataKeys] = useState<{ key: string, title: string, unit?: string }[]>([])

  const refreshData = (fy: number = reportPeriod) => {
    const currentReportPeriod = +JSON.stringify(fy)
    setLoading(true)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + currentReportPeriod,
      entityId: organizationId,
      sectors: [],
      regions: []
    }, "organization", "emissions", "scope").then((data) => {
      setChartData(data.data.data)

      if (data.data.data.length > 0) {
        let dataKeys = Object.keys(data.data.data[0]).filter((key: string) => !["FY"].includes(key))
        setDataKeys(dataKeys.map((key: string) => ({
          key,
          title: FormatKeyToLabel(key)
        })))

        const firstFY = data.data.data[0].FY
        setAllowedCompareToYears(currentReportPeriod - (+firstFY))
      }
    })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => setShowClimateChangeModal(true)}
        changeReportPeriodCallBack={(value: number) => refreshData(value)}
      />
      {
        loading
          ? <p>Loading...</p>
          : <RegionDataTable tableData={chartData.filter((data: any) => (+data.FY >= reportPeriod - compareToYears))}
            dataKeys={dataKeys}
            unitCellContent={<p className="opacity-40">tCO2e</p>}
          />
      }

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="w-full">
          <RegionDataTable tableData={chartData.filter((data: any) => (+data.FY >= reportPeriod - compareToYears))}
            dataKeys={dataKeys}
            unitCellContent={<p className="opacity-40">tCO2e</p>}
          />
        </Box>
      </VmModal>
    </>
  )
}

export default observer(SummeryAEScopeSection)
