import React, { useContext, useEffect, useState } from 'react'
import RegionDataTable, { TableDataKeyType } from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData, getOrganizationLandUseData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import { FormatKeyToLabel, GenerateFYOptions } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'


const SummaryLUSummarySection = observer(({ }: {}) => {
  const { reportPeriod, setAllowedCompareToYears, compareToYears } = useContext(FilterContext)

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const financialPeriods = GenerateFYOptions();

  const [dataKeys, setDataKeys] = useState<TableDataKeyType[]>([])

  const [unit, setUnit] = useState<string>("")

  const [loading, setLoading] = useState<boolean>(false)

  const refreshData = (fy: number = reportPeriod) => {
    setLoading(true)

    getOrganizationLandUseData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: organizationId,
      sectors: "all",
      regions: "all"
    }, "organization", "landuse", "summary").then((data) => {
      setChartData(data.data.data)

      if (data.data.data.length > 0) {
        setDataKeys([
          ...Object.keys(data.data.data[0])
            .filter((key: string) => !["FY", "total"].includes(key))
            .map((key: string) => ({
              key: key,
              title: FormatKeyToLabel(key),
            })),
          {
            key: "total",
            title: "Total",
          }
        ])

        const unit = data?.data?.data[0]?.total?.unit || ""
        setUnit(unit)

        const firstYear = data.data.data[0].FY

        setAllowedCompareToYears(reportPeriod - (+firstYear))
      } else {
        setDataKeys([])
        setUnit("")
        setAllowedCompareToYears(0)
      }
    })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    refreshData()
  }, [reportPeriod])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
        changeReportPeriodCallBack={(value: number) => { refreshData(value) }}
      />
      {
        loading
          ? <p>Loading...</p>
          : <RegionDataTable
            tableData={chartData.filter((data: any) => +data.FY >= reportPeriod - compareToYears)}
            dataKeys={dataKeys}
            unitCellContent={<>
              <p className="text-left opacity-80">Land Use Type</p>
              {unit && <p className="text-left text-sm opacity-40">{unit}</p>}
            </>}
          />
      }
    </>
  )
})

export default SummaryLUSummarySection
