import React, { useContext, useEffect, useState } from 'react'
import RegionDataTable, { TableDataKeyType } from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData, getOrganizationLandUseData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import { FormatKeyToLabel, GenerateFYOptions } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'


const SummaryRemovalsSummarySection = observer(({ }: {}) => {
  const { allowedCompareToYears, setAllowedCompareToYears, reportPeriod } = useContext(FilterContext)

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const financialPeriods = GenerateFYOptions();

  const [compareToYears, setCompareToYears] = useState<number>(3)
  const [dataKeys, setDataKeys] = useState<TableDataKeyType[]>([])

  const [unit, setUnit] = useState<string>("")

  useEffect(() => {

    setLoading(true)
    getOrganizationLandUseData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriod,
      entityId: organizationId,
      sectors: "all",
      regions: "all"
    }, "organization", "removals", "summary").then((data) => {
      setChartData(data.data.data)
      setDataKeys([
        ...Object.keys(data.data.data[0])
          .filter((key: string) => !["FY", "total"].includes(key))
          .map((key: string) => ({
            key: key,
            title: FormatKeyToLabel(key),
          })),
        {
          key: "total",
          title: "Total",
        }
      ])

      setUnit(data.data.data[0].total.unit)
    })
      .finally(() => setLoading(false))
  }, [reportPeriod])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
      />
      {
        loading
          ? <p>Loading...</p>
          : <RegionDataTable
            tableData={chartData.filter((data: any) => +data.FY >= reportPeriod - compareToYears)}
            dataKeys={dataKeys}
            unitCellContent={<p className="opacity-40">{unit}</p>}
          />
      }
    </>
  )
})

export default SummaryRemovalsSummarySection
