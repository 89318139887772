import React, { useState, createContext, Dispatch, SetStateAction, useEffect } from 'react';
import { SearchType } from '../utils/options';
import { GenerateFYOptions, GetCurrentFY } from '../utils/general';

interface FilterContextType {
  showBenchmark: boolean
  setShowBenchmark: Dispatch<SetStateAction<boolean>> | any
  showTarget: boolean
  setShowTarget: Dispatch<SetStateAction<boolean>> | any
  compareToYears: number
  setCompareToYears: Dispatch<SetStateAction<number>> | any
  reportPeriodOptions: { value: number, label: string }[]
  setReportPeriodOptions: Dispatch<SetStateAction<{ value: number, label: string }[]>> | any
  reportPeriod: number
  setReportPeriod: Dispatch<SetStateAction<number>> | any
  compareToFuture: number
  setCompareToFuture: Dispatch<SetStateAction<number>> | any,
  allowedCompareToYears: number
  setAllowedCompareToYears: Dispatch<SetStateAction<number>> | any

  searchType: SearchType
  setSearchType: Dispatch<SetStateAction<SearchType>> | any

  selectedRegions: string[]
  setSelectedRegions: Dispatch<SetStateAction<string[]>> | any
  selectedSectors: string[]
  setSelectedSectors: Dispatch<SetStateAction<string[]>> | any

  allRegionOptions: { value: string, label: string }[]
  setAllRegionOptions: Dispatch<SetStateAction<{ value: string, label: string }[]>> | any
}

const FilterContext = createContext<FilterContextType>({
  showBenchmark: false,
  setShowBenchmark: () => { },
  showTarget: false,
  setShowTarget: () => { },
  compareToYears: 3,
  setCompareToYears: () => { },
  reportPeriodOptions: [],
  setReportPeriodOptions: () => { },
  reportPeriod: 0,
  setReportPeriod: () => { },
  compareToFuture: 0,
  setCompareToFuture: () => { },
  allowedCompareToYears: 3,
  setAllowedCompareToYears: () => { },

  searchType: SearchType.REGION,
  setSearchType: () => { },

  selectedRegions: [],
  setSelectedRegions: () => { },
  selectedSectors: [],
  setSelectedSectors: () => { },

  allRegionOptions: [],
  setAllRegionOptions: () => { }
});

const FilterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [showBenchmark, setShowBenchmark] = useState(false)
  const [showTarget, setShowTarget] = useState(false)
  const [compareToYears, setCompareToYears] = useState<number>(3)
  const [reportPeriodOptions, setReportPeriodOptions] = useState<{ value: number, label: string }[]>([])
  const [reportPeriod, setReportPeriod] = useState<number>(GetCurrentFY() - 1)
  const [compareToFuture, setCompareToFuture] = useState<number>(0)
  const [allowedCompareToYears, setAllowedCompareToYears] = useState<number>(3)
  const [selectedRegions, setSelectedRegions] = useState<string[]>([])
  const [selectedSectors, setSelectedSectors] = useState<string[]>([])
  const [searchType, setSearchType] = useState<SearchType>(SearchType.REGION)
  const [allRegionOptions, setAllRegionOptions] = useState<{ value: string, label: string }[]>([])

  useEffect(() => {
    const fyOptions = GenerateFYOptions()
    setReportPeriodOptions(fyOptions)
    setReportPeriod(fyOptions[0].value)
  }, [])

  return (
    <FilterContext.Provider value={{
      showBenchmark, setShowBenchmark,
      showTarget, setShowTarget,
      compareToYears, setCompareToYears,
      reportPeriodOptions, setReportPeriodOptions,
      reportPeriod, setReportPeriod,
      compareToFuture, setCompareToFuture,
      allowedCompareToYears, setAllowedCompareToYears,
      selectedRegions, setSelectedRegions,
      selectedSectors, setSelectedSectors,
      searchType, setSearchType,
      allRegionOptions, setAllRegionOptions
    }}>
      {children}
    </FilterContext.Provider>
  );
};

export { FilterContext, FilterContextProvider };
