import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import RegionDataTableEntersLevel from '../../charts/region-data-table-enters-level'
import { GenerateFYOptions } from '../../../utils/general'
import CompareFilters from '../../CompareFilters'
import { FilterContext } from '../../../context/filter-bar-context'


const SummeryAERegionSection = observer(({ }: {}) => {
  const { selectedRegions, reportPeriod, compareToYears } = useContext(FilterContext)
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])

  const [dataKeys, setDataKeys] = useState<any>([])

  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  

  useEffect(() => {
    // console.log("summery ae scope section")

    // get region structure
    setLoading(true)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriod,
      entityId: organizationId,
      sectors: [],
      regions: selectedRegions || []
    }, "organization", "emissions", "region")
      .then((data) => {
        const chartData = data.data.data.sort((a: any, b: any) => (+a.FY) - (+b.FY));

        const dataKeys = Object.keys(chartData[0])
          .filter((key) => !["FY"].includes(key))
          .map((key) => ({ key, title: key }))

        setDataKeys(dataKeys)
        setChartData(chartData)
      })
      .finally(() => setLoading(false))
  }, [reportPeriod, selectedRegions])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
      />

      {
        loading
          ? <p>Loading...</p>
          : <RegionDataTableEntersLevel
            tableData={chartData.filter((data: any) => +data.FY >= reportPeriod - compareToYears)}
            reportPeriods={reportPeriod}
            dataKeys={dataKeys}
          />
      }
    </>
  )
})

export default SummeryAERegionSection
