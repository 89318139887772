import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import Layout from '../layouts/Layout';
import { FilterLevel, FilterLevelOne, FilterLevelThree, FilterLevelTwo } from '../components/summary/filter-section';
import { observer } from 'mobx-react-lite';
import { ArrowDownward, ArrowForwardIos, ArrowUpward, Info } from '@mui/icons-material';
import theme from '../styles/theme';

import { useNavigate } from 'react-router-dom';
import DashboardPieChart from '../components/charts/dashboard-pie-chart';
import DashbaordBarChart from '../components/charts/dashboard-bar-chart';
import ChangesLabel from '../components/changes-label';
import VmDropdown from '../components/VmDropdown';
import { FilterContext } from '../context/filter-bar-context';
import { getOrganizationAEData } from '../api/explorerService';
import { ClimateScenario } from '../utils/options';
import SecureStorage from '../services/secureStorage';
import { FormatKeyToLabel } from '../utils/general';

const FAKE_AE_DATA = [
  {
    "title": "Scope 1",
    "value": 536_478,
    "change": 3.25,
    "color": "#1d62f4"
  },
  {
    "title": "Scope 2",
    "value": 536_478,
    "change": 3.25,
    "color": "#1d2f53"
  },
  {
    "title": "Scope 3",
    "value": 536_478,
    "change": 3.25,
    "color": "#15f5ba"
  },
  {
    "title": "Gross Emissions",
    "value": 536_478,
    "change": 3.25,
  },
  {
    "title": "Removals",
    "value": 536_478,
    "change": 3.25,
  },
]

const FAKE_CARBON_REMOVALS_DATA = [
  {
    "title": "Total removals",
    "value": 968_231,
    "change": 3.25,
  },
  {
    "title": "Woody Vegetation",
    "value": 536_478,
    "change": 3.25,
  },
  {
    "title": "Soil Organic Carbon",
    "value": 536_478,
    "change": 3.25,
  },
  {
    "title": "Below Ground Biomass",
    "value": 536_478,
    "change": 3.25,
  },
  {
    "title": "Dead organic matter",
    "value": 536_478,
    "change": 3.25,
  },
]

const FAKE_FE_DATA = [
  {
    "title": "EEI",
    "value": 12533,
    "change": 3.25,
  },
  {
    "title": "PEI",
    "value": 75000,
    "change": 3.25,
  },
  {
    "title": "WACI",
    "value": 24533,
    "change": 3.25,
  },
]

const ChartHeight = "250px";


const SummaryDashboardPage = () => {
  const navigate = useNavigate();
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')

  const [aeSectionData, setAeSectionData] = useState<any>([])
  const [aeNetEmissions, setAeNetEmissions] = useState<{ value: number, change: number }>({ value: 0, change: 0 })
  const [aeUnit, setAeUnit] = useState<string>("tCO2e")

  // loading states
  const [aeLoading, setAeLoading] = useState<boolean>(false)

  const { reportPeriod, setReportPeriod, reportPeriodOptions } = useContext(FilterContext)

  // TODO: ADD CHECKS FOR SHOWING FINANCED EMISSIONS
  const showFinancedEmissions = true

  const colors = ["#1d62f4", "#1d2f53", "#15f5ba"]

  useEffect(() => {
    // Get Absolute Emissions Data
    setAeLoading(true)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriod,
      entityId: organizationId,
      sectors: [],
      regions: []
    }, "organization", "emissions", "scope").then((data) => {
      const fyData = data.data.data.find((data: any) => +data.FY === reportPeriod)
      const scopeData: { title: string, value: number, change: number, color: string }[] = []

      Object.keys(fyData)
        .filter((key: string) => (key.startsWith("Scope") || ["NetEmissions", "GrossEmission", "CarbonRemovals"].includes(key)))
        .forEach((key: string, keyIndex: number) => {
          scopeData.push({
            title: FormatKeyToLabel(key),
            value: +fyData[key].value,
            change: +fyData[key].change,
            color: key.startsWith("Scope") ? colors[keyIndex] : ""
          })
        })

      setAeNetEmissions(fyData["NetEmissions"])
      // setAeUnit(fyData["NetEmissions"].unit)
      setAeSectionData(scopeData)
    })
      .finally(() => setAeLoading(false))
  }, [reportPeriod])

  return (
    <Layout onTriggerAfterSearch={(search: string) => { console.log(search) }} >

      <Box className="flex flex-col gap-4">
        <Box>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>Hi, Sam</Typography>
        </Box>

        {/* FILTER ROW */}
        <Box className="border rounded-md p-4 py-2 bg-gray-100 flex justify-between ">
          <Box className="flex gap-4 items-center">
            <p className='font-bold'>Report Period</p>
            <VmDropdown label="" value={reportPeriod} onChange={setReportPeriod} options={reportPeriodOptions} />

            {/* <p>Turn out</p> 
        <Box className="flex gap-1 items-center bg-[#ebf1fd] px-2 py-1 rounded-md">
          <p>xx%</p>
          <ArrowUpward sx={{ fontSize: 20 }} color='error' />
        </Box> */}
          </Box>
        </Box>

        <Box className="grid grid-cols-4 gap-4">
          <DashboardStatCard title='No. of assets' value={50} unit='#' />
          <DashboardStatCard title='Total Finance' value={"8,743,816"} unit='$' />
          <DashboardStatCard title='Financed Emissions' value={"443,577"} unit='tCO2e' />
          <DashboardStatCard title='Data Quality Score' value={5.5} unit='out of 10' />
        </Box>

        <Box className="grid grid-cols-2 gap-4">

          {/* AE CARD */}
          <Box className="border rounded-lg p-4 bg-white min-h-60">
            <Box className="flex justify-between items-start">
              <Box className="flex flex-col gap-2">
                <Box className="flex items-center gap-2">
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>Absolute Emissions</Typography>
                  <IconButton>
                    <Info />
                  </IconButton>
                </Box>
                <p className=" text-sm">{aeUnit}</p>
              </Box>

              <button onClick={() => navigate(`/summary?lv1=${FilterLevelOne.AE}&lv2=${FilterLevelTwo.AE_EMISSIONS}&lv3=${FilterLevelThree.AE_EMISSION_EMISSION_SUMMARY}`)}>
                <p className="text-lg" style={{ color: theme.palette.primary.main }}>View More <ArrowForwardIos sx={{ fontSize: 16 }} /></p>
              </button>
            </Box>

            <Box className="flex gap-2 w-full">
              <Box className="flex-1 relative" sx={{ height: ChartHeight }}>
                {
                  aeLoading
                    ? <Skeleton variant="circular" height="100%" sx={{ aspectRatio: "1/1", margin: "auto" }} animation="pulse" />
                    : <DashboardPieChart series={
                      aeSectionData
                        .filter((data: any) => data.title.startsWith("Scope"))
                        .map((data: any) => ({
                          // name: data.title,
                          value: data.value,
                          itemStyle: {
                            normal: {
                              color: data.color
                            }
                          }
                        }))
                    } />
                }
                <Box className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex flex-col items-center justify-center">
                  {
                    aeLoading
                      ? <>
                        <Skeleton variant="text" width="5rem" height="2rem" animation="pulse" />
                        <Skeleton variant="text" width="7rem" height="2rem" animation="pulse" />
                      </>
                      : <ValueStatCard title="Net Emissions" value={(+aeNetEmissions.value).toLocaleString()} changeAmount={+aeNetEmissions.change} background='none' />
                  }
                </Box>
              </Box>

              <Box className="grid grid-cols-2 items-center gap-2 bg-[#f7f9fd] p-2 rounded-lg w-80">
                {
                  aeSectionData.map((data: any, i: number) => (
                    aeLoading
                      ? <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" className='col-span-2' />
                      : <>
                        <Box className="flex gap-2 items-center justify-start">
                          <Box className="w-3 h-3 rounded-sm" style={{ background: data.color }} />
                          <p>{data.title}</p>
                        </Box>
                        <Box className="flex gap-2 items-center justify-end">
                          <p className="text-sm font-bold text-end">{data.value.toLocaleString()}</p>
                          <ChangesLabel changeAmount={data.change} />
                        </Box>
                      </>
                  ))
                }
              </Box>
            </Box>
          </Box>

          {/* FE CARD */}
          {
            showFinancedEmissions && <Box className="border rounded-lg p-4 bg-white min-h-60">
              <Box className="flex justify-between items-start">
                <Box className="flex flex-col gap-2">
                  <Box className="flex items-center gap-2">
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>Financed Emissions</Typography>
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Box>
                  <p className=" text-sm">tCO2e</p>
                </Box>

                <button onClick={() => navigate(`/summary?lv1=${FilterLevelOne.FINANCED}&lv2=${FilterLevelTwo.FINANCED_EMISSIONS}&lv3=${FilterLevelThree.FINANCED_EMISSION_SCOPE}`)}>
                  <p className="text-lg" style={{ color: theme.palette.primary.main }}>View More <ArrowForwardIos sx={{ fontSize: 16 }} /></p>
                </button>
              </Box>

              <Box className="flex gap-4 w-full">
                <Box sx={{ height: ChartHeight }}>
                  <ValueStatCard title="PCAF Score" value={2.3} changeAmount={-3.25} unit="out of 10" className=' h-full px-8' />
                </Box>

                <Box className="flex-1" sx={{ height: ChartHeight }}>
                  <DashbaordBarChart
                    series={FAKE_FE_DATA.map((data: any) => ({
                      title: data.title,
                      value: data.value,
                    }))}
                    height={ChartHeight}
                  />
                </Box>
              </Box>
            </Box>
          }

          {/* Carbon Removals CARD */}
          <Box className="border rounded-lg p-4 bg-white min-h-60">
            <Box className="flex justify-between items-start">
              <Box className="flex flex-col gap-2">
                <Box className="flex items-center gap-2">
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>Carbon Removals</Typography>
                  <IconButton>
                    <Info />
                  </IconButton>
                </Box>
                <p className=" text-sm">tCO2e</p>
              </Box>

              <button onClick={() => navigate(`/summary?lv1=${FilterLevelOne.LAND_USE_AND_REMOVALS}&lv2=${FilterLevelTwo.LAND_USE_LAND_USE}&lv3=${FilterLevelThree.LAND_USE_SUMMARY}`)}>
                <p className="text-lg" style={{ color: theme.palette.primary.main }}>View More <ArrowForwardIos sx={{ fontSize: 16 }} /></p>
              </button>
            </Box>

            <Box className="grid grid-cols-3 grid-rows-2 gap-4 bg-white min-h-60">
              {
                FAKE_CARBON_REMOVALS_DATA.map((data: any, i: number) => (
                  <ValueStatCard key={`carbon_removals_${i}`} title={data.title} value={data.value} changeAmount={data.change} className={i === 0 ? "row-span-2" : ""} />
                ))
              }
            </Box>
          </Box>

          <Box className="grid grid-cols-2 gap-4">

            {/* Climate Risk Score CARD */}
            <Box className="border rounded-lg p-4 bg-white min-h-60 ">
              <Box className="flex items-center gap-2">
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>Climate Risk Score</Typography>
                <IconButton>
                  <Info />
                </IconButton>
              </Box>

              <Box className="flex w-full h-full ">
                <ValueStatCard title="Climate Risk Score" value={2.3} changeAmount={3.25} unit="out of 10" />
              </Box>
            </Box>

            {/* Quick Link CARD */}
            <Box className="border rounded-lg p-4 bg-white min-h-60 flex flex-col gap-4">
              <Box className="flex justify-between items-start">
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>Quick Link</Typography>
              </Box>

              <Box className=" flex flex-col gap-2 my-auto">
                <QuickLinkButton label='Physical Risk' onClick={() => navigate(`/summary?lv1=${FilterLevelOne.PHYSICAL_RISK}&lv2=${FilterLevelTwo.PHYSICAL_RISK_ACUTE}&lv3=${FilterLevelThree.PHYSICAL_RISK_ACUTE_DROUGHT}`)} />
                <QuickLinkButton label='Owner list' onClick={() => navigate("/owners")} />
                <QuickLinkButton label='Asset list' onClick={() => navigate("/assets")} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout >
  );
};

const QuickLinkButton = ({ label, onClick }: { label: string, onClick: () => any }) => {

  return (
    <button onClick={onClick}>
      <Box className="flex items-center justify-between gap-2 bg-[#f7f9fd] p-2 rounded-lg">
        <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "18px" }}>{label}</Typography>
        <Box className="w-12 h-12 flex items-center justify-center rounded-md" sx={{ background: "#0061ff", color: 'white' }}>
          <ArrowForwardIos sx={{ fontSize: 20 }} />
        </Box>
      </Box>
    </button>
  )
}

const DashboardStatCard = ({ title, value, unit }: { title: string, value: number | string, unit: string }) => {
  return (
    <Box className="flex flex-col gap-1 border rounded-lg p-4 items-center bg-white ">
      <p className=" opacity-70">{title}</p>
      <Typography variant="h3" component={'span'} color='primary' sx={{ fontWeight: "bold" }}>{value}</Typography>
      <p className=" opacity-70">{unit}</p>
    </Box>
  );
};

const ValueStatCard = ({ title, value, changeAmount, unit, className, background = "#f7f9fd" }: { title: string, value: number | string, changeAmount: number, className?: string, unit?: string, background?: string }) => {

  return (
    <Box className={`rounded-lg p-2 flex w-full flex-col items-center px-4 justify-center gap-1 ${className}`} sx={{ background: background }}>
      <p>{title}</p>
      <p className="text-[2rem] font-bold">{value.toLocaleString()}</p>
      <ChangesLabel changeAmount={changeAmount} />
      {unit && <p className="text-sm opacity-70">{unit}</p>}
    </Box>
  )
}

export default observer(SummaryDashboardPage);
