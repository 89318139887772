import React, { useEffect, useState } from 'react'
import RegionDataTable, { TableDataKeyType } from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData, getOrganizationLandUseData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import { FormatKeyToLabel, GenerateFYOptions } from '../../../utils/general'
import { Box } from '@mui/material'
import DonutGraph from '../../charts/dashboard-pie-chart'


const SummaryCarbonStockTypeSection = observer(({ }: {}) => {
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const financialPeriods = GenerateFYOptions();

  const [reportPeriods, setReportPeriods] = useState<number>(financialPeriods[0].value)
  const [compareToYears, setCompareToYears] = useState<number>(3)

  const [dataSeries, setDataSeries] = useState<any[]>([])
  const [dataKeys, setDataKeys] = useState<string[]>([])
  const [unit, setUnit] = useState<string>("")

  const colors = ["#0061ff", "#018c68", "#ffe3a3", "#ff9900"]

  useEffect(() => {
    setLoading(true)
    getOrganizationLandUseData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriods,
      entityId: organizationId,
      sectors: "all",
      regions: "all"
    }, "organization", "removals", "carbon_stock_type").then((data) => {
      const fySeries = data.data.data;
      const dataKeys = Object.keys(fySeries[0]).filter((key: string) => !["FY", "total"].includes(key));

      let series = fySeries.map((data: any, index: number) => {
        return {
          data: dataKeys.map((key: string, dataKeyIndex: number) => ({
            name: FormatKeyToLabel(key),
            itemStyle: {
              color: colors[dataKeyIndex]
            },
            ...data[key]
          })),
          FY: data.FY,
          total: data.total
        }
      })

      setDataSeries(series)
      setDataKeys(dataKeys)

      setUnit(data.data.data[0].total.unit)
    })
      .finally(() => setLoading(false))
  }, [reportPeriods])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
      />

      {
        loading
          ? <p>Loading...</p>
          :
          <Box className="mt-4 flex gap-8 w-full h-full">
            <Box className="bg-gray-100 rounded-lg p-4 h-full flex-1 flex flex-col gap-1 m-auto">
              <p className="text-lg font-bold">Carbon Stock Type</p>
              {
                dataKeys.map((key: string, index: number) => (
                  <Box className="flex gap-2 items-center">
                    <Box className="w-3 h-3 rounded-sm" style={{ background: colors[index] }} />
                    <p className=" whitespace-nowrap">{FormatKeyToLabel(key)}</p>
                  </Box>
                ))
              }
            </Box>

            <Box className="w-[80%] h-full flex flex-col gap-8 mx-8">
              <Box className="grid" sx={{ gridTemplateColumns: `repeat(${dataSeries.length}, minmax(0, 1fr))` }}>
                {
                  dataSeries.map((series: any, index: number) => (
                    <Box key={index}>
                      <Box className="relative">
                        <DonutGraph series={series.data} showLabel={false} />
                        <Box className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                          <p className="text-center">Total</p>
                          <p className="text-[1.6rem] text-center font-bold">{series.total.value.toLocaleString()}</p>
                        </Box>
                      </Box>
                      <p className="text-center mt-2">{series.FY}</p>
                    </Box>
                  ))
                }
              </Box >
              <p className="text-center">Total Carbon Stock (tC)</p>
            </Box >
          </Box >
      }
    </>
  )
})

export default SummaryCarbonStockTypeSection
