import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import React, { useEffect, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles';

const rangeLabelAppend = ["Low", "Medium", "High"];

const SingleBarChart = ({ data, onClickBar }: {
  data?: {
    benchmark: number,
    target: number,
    data: {
      rangeLabel: string,
      rangeStart: number,
      rangeEnd: number,
      rangeStart_rawValue: number,
      rangeEnd_rawValue: number,
      value: number,
    }[],
    unit: { emissionIntensity: string },
  },
  onClickBar?: (clickedBinIndex: number) => void
}) => {
  const theme = useTheme();

  const [series, setSeries] = useState<ApexOptions["series"]>([{
    name: 'Number of Farms',
    data: [0, 0, 0]
  },]);

  const [options, setOptions] = useState<ReactApexChart['props']>({
    chart: {
      type: 'bar',
      height: 350,
      events: {
        click: (event: any, chartContext: any, config: any) => {
          // console.log(event, chartContext, config)
          onClickBar && onClickBar(config.dataPointIndex)
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '15%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      // show: true,
      // width: 2,
      // colors: ['transparent']
    },
    xaxis: {
      categories: ['-', '-', '-'],
      title: {
        text: '-'
      },
    },
    yaxis: {
      title: {
        text: 'Number of Farms'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: (val: string) => {
          return `${val}`
        }
      }
    },
  });

  useEffect(() => {
    if (!data) return;
    if (!data.data) return;

    setSeries([
      {
        name: 'Number of Farms',
        data: data.data.map((item: any) => item.value),
      },
    ]);

    setOptions(options => {
      let newOptions = { ...options };

      let titles = data.data.map((item: any, itemIndex: number) => {
        return `${rangeLabelAppend[itemIndex]} ${item.rangeLabel}`;
      })

      newOptions.annotations = { points: [] }
      newOptions.xaxis.categories = titles;
      newOptions.xaxis.title.text = `${data.unit.emissionIntensity}`;


      let benchmarkBinIndex = -1;
      let targetBinIndex = -1;

      if (data.benchmark) {

        data.data.forEach((range: any, rangeIndex: number) => {
          if (benchmarkBinIndex !== -1) return;

          if (data.benchmark <= range.rangeEnd_rawValue) {
            benchmarkBinIndex = rangeIndex;
          }
        })

        newOptions.annotations.points.push({
          x: titles[benchmarkBinIndex],
          y: data.data[benchmarkBinIndex].value,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '16px',
              color: '#333',
              background: '#ffe3a3',
            },
            text: `Benchmark - ${+data.benchmark.toFixed(2)}`,
          }
        });
      }

      if (data.target !== null) {

        data.data.forEach((range: any, rangeIndex: number) => {
          if (targetBinIndex !== -1) return;

          if (data.target <= range.rangeEnd_rawValue) {
            targetBinIndex = rangeIndex;
          }
        })

        newOptions.annotations.points.push({
          x: titles[targetBinIndex],
          y: data.data[targetBinIndex].value,
          label: {
            borderColor: '#333',
            offsetY: targetBinIndex === benchmarkBinIndex ? 40 : 0,
            style: {
              fontSize: '16px',
              color: '#333',
              background: '#bfecff',
            },
            text: `Target - ${+data.target.toFixed(2)}`,
          }
        });
      }

      return newOptions
    })
  }, [data])

  if (!data) return <>loading...</>;

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type='bar' height={400} events={options.events} />
    </div>
  );
}

export default SingleBarChart
