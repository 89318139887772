import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FormatKeyToLabel } from '../../utils/general';
import { lerp, lerpColor } from '../../utils/colour';

interface ProcessedGridData {
  childrenCount: number;
  name: string;
  description?: string;
  bulletpoints?: string;
  value: number;
  color: string;
  children: ProcessedGridData[];
}

const colorSets = [
  { start: "#19154d", end: "#0958d9" },
  { start: "#002c8c", end: "#256bff" },
  { start: "#0958d9", end: "#548bff" },
]

const GHGGrid = observer(({ data }: { data: any }) => {
  const [maximumLayers, setMaximumLayers] = useState(0);
  const [processedData, setProcessedData] = useState<ProcessedGridData[]>([]);

  // TODO: REMOVE TEST DATA
  const FakeData = {
    "data": [
      {
        "FY": 2024,
        "Scope1": {
          "bulletpoints": "Enteric fermentation, Manure management, Field burning",
          "description": "",
          "value": 2214,
          "change": 0.0235,
          "children": [
            {
              "name": "Biological",
              "Total": 1214,
              "children": [
                {
                  "name": "Methane",
                  "description": "Primarily produced from cows",
                  "value": 100
                },
                {
                  "name": "Nitrous Oxide",
                  "description": "....",
                  "value": 6299
                }
              ]
            },
            {
              "name": "Non-biological",
              "Total": 1214,
              "children": [
                {
                  "name": "Carbon Dioxide",
                  "description": "Primarily produced from cows",
                  "value": 2214
                }
              ]
            }
          ]
        },
        "Scope2": {
          "children": [
            {
              "name": "Non-biological",
              "Total": 1214,
              "children": [
                {
                  "name": "Carbon Dioxide",
                  "description": "Primarily produced from cows",
                  "value": 2214
                }
              ]
            }
          ]
        },
        "Scope3": {},
        "GrossEmission": {
          "value": 0.001
        },
        "CarbonRemovals": {
          "value": 0.001
        },
        "NetEmissions": {
          "value": 0.001
        }
      }
    ]
  }

  const getLayers = (data: any) => {
    let maximumLayers = 0;

    const hasNextLayer = (layer: any, parentLayer: any) => {
      const currentLayer = parentLayer + 1;

      if (layer.children && layer.children.length > 0) {
        layer.children.forEach((child: any) => {
          hasNextLayer(child, currentLayer)
        })
      }

      if (currentLayer > maximumLayers) {
        maximumLayers = currentLayer
      }

      return;
    }

    Object.keys(data).forEach((key: any) => {
      hasNextLayer(data[key], 0)
    })

    return maximumLayers;
  }

  const processData = (data: any) => {
    let tempProcessedData: any = [];

    const countChildren = (data: any, layer: number) => {
      let count = 0;
      if (data.children && data.children.length > 0) {
        data.children.forEach((child: any) => {
          count += countChildren(child, layer + 1)
        })
        data.childrenCount = count;
      } else if (layer !== 0) {
        count += 1;
      }

      data.childrenCount = count;
      return count;
    }

    Object
      .keys(data)
      // .filter((key: any) => !["FY", "GrossEmission", "NetEmissions"].includes(key))
      .filter((key: any) => key.toLowerCase().startsWith("scope"))
      .forEach((key: any) => {
        tempProcessedData.push({
          ...data[key],
          name: FormatKeyToLabel(key),
          childrenCount: countChildren(data[key], 0)
        })
      })

    return tempProcessedData;
  }

  useEffect(() => {
    setMaximumLayers(getLayers(FakeData.data[0]))
    setProcessedData(processData(FakeData.data[0]))
  }, [])

  // console.log(processedData)

  return (
    <Box className='mt-4 flex flex-col gap-2'>
      <Box className='flex gap-4 items-center'>
        <p className='text-[1.2rem] font-bold'>Total GHG Emissions</p>
        <p className='text-[1.6rem] font-bold'>{FakeData.data[0].GrossEmission ? FakeData.data[0].GrossEmission.value : "--"}</p>
      </Box>
      <p className=' text-gray-500'>tCO2e</p>

      <Box className='grid gap-1' sx={{ gridTemplateColumns: `repeat(${maximumLayers}, 1fr)` }}>
        {processedData.map((data: any, scopeIndex: number) => (
          <GHGGridItem gridLayer={data} currentLayer={0} maximumLayers={maximumLayers} fillInCellsCount={maximumLayers - 1} colorSet={colorSets[scopeIndex]} />
        ))}
      </Box>
    </Box>
  )
})

const GHGGridItem = ({ gridLayer, currentLayer, fillInCellsCount, colorSet, maximumLayers }: { gridLayer: ProcessedGridData, currentLayer: number, fillInCellsCount: number, colorSet: { start: string, end: string }, maximumLayers: number }) => {
  // console.log(gridLayer, fillInCellsCount)
  const bgColor = lerpColor(colorSet.start, colorSet.end, currentLayer / maximumLayers);

  return (
    <>
      <Box
        key={gridLayer.name}
        className='p-3 rounded-lg flex justify-between'
        sx={{
          gridRow: `span ${gridLayer.childrenCount} / span ${gridLayer.childrenCount}`,
          backgroundColor: bgColor,
          color: "#fff"
        }}>
        <Box className='mt-auto'>
          <p className='font-bold text-[1.2rem]'>{gridLayer.name}</p>
          <p>{gridLayer.description}</p>
          {
            gridLayer.bulletpoints && <ul className='list-disc list-inside'>
              {gridLayer.bulletpoints.split(",").map((bulletpoint: string) => (
                <li>{bulletpoint}</li>
              ))}
            </ul>
          }
        </Box>
        <Box>
          {
            gridLayer.value
              ? <p className='font-bold text-[1.2rem]'>{gridLayer.value.toLocaleString()}</p>
              : <p className='font-bold text-[1.2rem] opacity-30'>--</p>
          }
        </Box>
      </Box>

      {
        gridLayer.childrenCount === 0
          ? <>
            {
              fillInCellsCount > 0 && Array.from({ length: fillInCellsCount }).map((_, index) => (
                <Box key={index} className='h-full w-full flex items-center justify-center border border-gray-300 text-gray-400 rounded-lg' >
                  <p>--</p>
                </Box>
              ))
            }
          </>
          : gridLayer.children && gridLayer.children.length > 0 && gridLayer.children.map((child: any) => (
            <GHGGridItem gridLayer={child} currentLayer={currentLayer + 1} fillInCellsCount={fillInCellsCount - 1} colorSet={colorSet} maximumLayers={maximumLayers} />
          ))
      }
    </>
  )
}

export default GHGGrid
