import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import RegionDataTableEntersLevel from '../../charts/region-data-table-enters-level'
import { GenerateFYOptions } from '../../../utils/general'
import CompareFilters from '../../CompareFilters'


const SummaryFERegionSection = observer(({ }: {}) => {
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])

  const financialPeriods = GenerateFYOptions();

  const [reportPeriods, setReportPeriods] = useState<number>(financialPeriods[0].value)
  const [compareToYears, setCompareToYears] = useState<number>(3)
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)

  useEffect(() => {
    // console.log("summery ae scope section")

    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriods,
      entityId: organizationId,
      sectors: [],
      regions: []
    }, "organization", "emissions", "region").then((data) => {
      const chartData = data.data.data;

      setChartData(
        chartData
          .sort((a: any, b: any) => a.FY - b.FY)
          .map((a: any) => ({ ...a, FY: +a.FY }))
      )
    })
  }, [])

  // console.log(chartData)

  // TODO: CHANGE THIS BACK TO FINANCED

  const dataKeys = [
    { key: "NSW", title: "NSW", },
    { key: "NT", title: "NT", },
    { key: "QLD", title: "QLD", },
    { key: "SA", title: "SA", },
    { key: "VIC", title: "VIC", },
    { key: "WA", title: "WA", },
  ]

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
      />

      <RegionDataTableEntersLevel
        tableData={chartData.filter((data: any) => +data.FY >= reportPeriods - compareToYears)}
        reportPeriods={reportPeriods}
        dataKeys={dataKeys}
      />
    </>
  )
})

export default SummaryFERegionSection