import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import SectorCompareBarChart from '../../charts/sector-compare-bar-chart'
import CompareFilters from '../../CompareFilters'
import { GenerateFYOptions } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'


const SummeryAESectorSection = ({ }: {}) => {
  const { reportPeriod, compareToYears, setAllowedCompareToYears } = useContext(FilterContext)
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])

  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [dataKeys, setDataKeys] = useState<{ key: string, title: string }[]>([])

  const refreshData = (fy: number = reportPeriod) => {
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: organizationId,
      sectors: [],
      regions: []
    }, "organization", "emissions", "sector").then((data) => {
      setChartData(data.data.data.sort((a: any, b: any) => +a.FY - +b.FY))

      if (data.data.data.length > 0) {
        setDataKeys(Object
          .keys(data.data.data[0])
          .filter((key: string) => ["FY", "Removals", "Net Emissions"].includes(key) === false)
          .map((key: string) => ({ key, title: key }))
        )

        const firstFY = data.data.data[0].FY
        setAllowedCompareToYears(fy - (+firstFY))
      }
    })
  }

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => setShowClimateChangeModal(true)}
        changeReportPeriodCallBack={(value: number) => refreshData(value)}
      />

      <SectorCompareBarChart
        data={chartData.filter((data: any) => +data.FY >= reportPeriod - compareToYears)}
        reportPeriods={reportPeriod}
        dataKeys={dataKeys}
      />
    </>
  )
}

export default observer(SummeryAESectorSection)
