import axios from 'axios';
import SecureStorage from '../services/secureStorage'; // Updated import for the singleton SecureStorage
import { ClimateScenario } from '../utils/options';

const API_VERSION = 'v2';

// ==============================|| ORGANIZATION ||============================== //

// Type definitions for the function parameters
interface EntityListParams {
    asset_owner_id?: number | null;
    asset_id?: number | null;
    financial_year?: string | null;
    include_trajectory?: boolean;
    entityType?: string;
}

interface SearchEntityListParams {
    search_term?: string;
    financial_year?: string | null;
    entityType?: string;
}

interface OrganizationSummaryParams {
    financial_year?: string;
    type?: string | null;
}

interface PortfolioClimateScoreParams {
    financial_year?: string | null;
}

interface EmissionDecilesParams {
    financial_year?: string | null;
}

interface NetEmissionDecilesParams extends EmissionDecilesParams {
    lower_band?: number;
    higher_band?: number;
}

interface EmissionBarChartDataParams {
    entity_id?: number | null;
    financial_year?: string | null;
}

interface PortfolioPCAFScoreParams {
    financial_year?: string;
}

interface KeyInformationParams {
    financial_year?: string;
}

interface OrganizationStatisticsParams {
    type?: string;
    financial_year?: string;
}

interface AssetGeoJsonParams {
    entity_id: number;
    financial_year: string;
}

interface CustomerStatisticsParams {
    entity_id?: number | null;
    financial_year?: string | null;
    type?: string | null;
    entity?: string;
}

interface CustomerLandUseAndRemovalParams {
    entity_id?: number | null;
    financial_year?: string | null;
}

interface CustomerBenchmarkingParams {
    financial_year?: string | null;
    benchmarkingType: string;
}

interface CustomerSummaryParams {
    entity_id?: number | null;
    financial_year?: string | null;
    entity_type?: string;
}

// General function to handle the Axios requests and return the data
async function postRequest(url: string, data: any, token: string) {
    try {
        const response = await axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (err) {
        throw err;
    }
}

// Example: Get Entity List
export async function getEntityList(params: EntityListParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${params.entityType || 'asset_owner'}/list`;
    const data = {
        asset_owner_id: params.asset_owner_id,
        asset_id: params.asset_id,
        financial_year: params.financial_year || '2023',
        include_trajectory: params.include_trajectory || true,
    };

    return postRequest(url, data, token);
}

// Example: Search Entity List
export async function searchEntityList(params: SearchEntityListParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${params.entityType || 'asset_owner'}/search`;
    const data = {
        search_term: params.search_term || '',
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// Example: Get Organization Summary
export async function getOrganizationSummary(params: OrganizationSummaryParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/summary`;
    const data = {
        financial_year: params.financial_year || '2023',
        type: params.type,
    };

    return postRequest(url, data, token);
}

// Example: Get Portfolio Climate Score Summary
export async function getPortfolioClimateScoreSummary(params: PortfolioClimateScoreParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/climate_score/average`;
    const data = {
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// Example: Get Emission Deciles
export async function getPortfolioClimateScoreDeciles(params: EmissionDecilesParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/climate_score/deciles`;
    const data = {
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// Example: Get Portfolio Net Emission Deciles
export async function getPortfolioNetEmissionDeciles(params: NetEmissionDecilesParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/net_emission/deciles`;
    const data = {
        financial_year: params.financial_year || '2023',
        lower_band: params.lower_band || 5000,
        higher_band: params.higher_band || 30000,
    };

    return postRequest(url, data, token);
}

// Example: Get Emission Intensity Bar Chart Data
export async function getEmissionIntensityBarChartData(params: EmissionBarChartDataParams, entity_type = 'organization') {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/emission_intensities`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// Example: Get Portfolio PCAF Score
export async function getPortfolioPCAFScore(params: PortfolioPCAFScoreParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/data_quality_score`;
    const data = {
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// Example: Get Portfolio Key Information
export async function getPortfolioKeyInformation(params: KeyInformationParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/key_information`;
    const data = {
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// Example: Get Organization Statistics Graph Data
export async function getOrganizationStatisticsGraphData(params: OrganizationStatisticsParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/statistics`;
    const data = {
        type: params.type || 'sector_emissions',
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// option apis
export async function getRegionOptions(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climate_scenario: string,
    report_period: string,
    region_type: string
}) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/options/regions`;
    const data = {
        ...params,
        report_period: params.report_period || '2023',
    };

    return postRequest(url, data, token);
}

export async function getSectorOptions(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    report_period: string
}) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/options/sectors`;
    const data = {
        ...params,
        report_period: params.report_period || '2023',
    };

    return postRequest(url, data, token);
}

export async function getOrganizationAEData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
    sectors: string[],
    regions: string[]
},
    entity_type: "organization" | "asset_owner" | "asset",
    second_filter: "emissions" | "physical",
    third_filter: "scope" | "sector" | "region" | "emissions_intensity_per_hectare_by_farm" | "non_biological_emissions_intensity_by_scope" | "above_ground_carbon_mass" | "soil_carbon" | "below_ground_carbon_mass"
) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/ae/${second_filter}/${third_filter}`;
    const data = {
        ...params,
        reportPeriod: params.reportPeriod || '2023',
    };

    return postRequest(url, data, token);
}

export async function getOrganizationEmissionFarmListData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
    sectors: string[] | "all",
    regions: string[] | "all",
    rangeStart: number,
    rangeEnd: number
},
    entity_type: "organization" | "asset_owner" | "asset",
) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/ae/physical/emission_per_ha_by_farm/search`;
    const data = {
        ...params,
        reportPeriod: params.reportPeriod || '2023',
    };

    return postRequest(url, data, token);
}


export async function getOrganizationFEData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
    sectors: string[],
    regions: string[]
},
    entity_type: "organization" | "asset_owner" | "asset",
    second_filter: "emissions" | "physical",
    third_filter: "scope" | "sector" | "region" | "emissions_intensity_per_hectare_by_farm" | "non_biological_emissions_intensity_by_scope" | "above_ground_carbon_mass" | "soil_carbon" | "below_ground_carbon_mass"
) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/ae/${second_filter}/${third_filter}`;
    const data = {
        ...params,
        reportPeriod: params.reportPeriod || '2023',
    };

    return postRequest(url, data, token);
}


export async function getOrganizationLandUseData(params: {
    force_refresh: boolean,
    include_trajectory: boolean,
    climateScenario: ClimateScenario,
    reportPeriod: string,
    entityId: string,
    sectors: string[] | "all",
    regions: string[] | "all"
},
    entity_type: "organization" | "asset_owner" | "asset",
    second_filter: "landuse" | "removals",
    third_filter: "summary" | "map" | "land_use_type" | "carbon_stock_type"
) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${entity_type}/summary/lu/${second_filter}/${third_filter}`;
    const data = {
        ...params,
        reportPeriod: params.reportPeriod || '2023',
    };

    return postRequest(url, data, token);
}


// ==============================|| CUSTOMERS ||============================== //

// Example: Get Asset GeoJson
export async function getAssetGeoJson(params: AssetGeoJsonParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/asset/geojson`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// Example: Get Customer Statistics
export async function getCustomerStatistics(params: CustomerStatisticsParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${params.entity || 'asset_owner'}/statistics`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
        type: params.type,
    };

    return postRequest(url, data, token);
}

// Example: Get Customer Land Use and Removal
export async function getCustomerLandUseAndRemoval(params: CustomerLandUseAndRemovalParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/v0/portfolio/asset/landuse_and_removals`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// Example: Get Customer Benchmarking
export async function getCustomerBenchmarking(params: CustomerBenchmarkingParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/organization/benchmarkingType`;
    const data = {
        benchmarkingType: params.benchmarkingType,
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}

// Example: Get Customer Summary
export async function getCustomerSummary(params: CustomerSummaryParams) {
    const secureStorage = SecureStorage.getInstance();
    const token = secureStorage.getJwtToken();

    if (!token) {
        throw new Error('No JWT token found');
    }

    const url = `${process.env.REACT_APP_API_BASEURL}/${API_VERSION}/portfolio/${params.entity_type || 'asset_owner'}/summary`;
    const data = {
        entity_id: params.entity_id,
        financial_year: params.financial_year || '2023',
    };

    return postRequest(url, data, token);
}
