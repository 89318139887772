import React, { useContext, useEffect, useState } from 'react'
import RegionDataTable from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import moment from 'moment'
import { GenerateFYOptions } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'


const SummeryFEScopeSection = observer(({ }: {}) => {
  const { reportPeriod, compareToYears, compareToFuture, setCompareToYears, setAllowedCompareToYears } = useContext(FilterContext)

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])

  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const dataKeys = [
    {
      key: "Scope1",
      title: "Scope 1",
    },
    {
      key: "Scope2",
      title: "Scope 2",
    },
    {
      key: "Scope3",
      title: "Scope 3",
    },
    {
      key: "CarbonRemovals",
      title: "Carbon Removals",
    },
    {
      key: "GrossEmission",
      title: "Gross Emission",
    },
    {
      key: "NetEmissions",
      title: "Net Emissions",
    },
    {
      key: "NetIntensity",
      title: "Net Intensity",
      unit: "tCO2e/ha"
    },
    {
      key: "PhysicalIntensity",
      title: "Physical Intensity",
    }
  ]
  
  // TODO: CHANGE THIS BACK TO FINANCED

  const refreshData = (fy: number = reportPeriod) => {
    const currentReportPeriod = +JSON.stringify(fy)
    setLoading(true)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + currentReportPeriod,
      entityId: organizationId,
      sectors: [],
      regions: []
    }, "organization", "emissions", "scope").then((data) => {
      setChartData(data.data.data)
      if (data.data.data.length > 0) {
        const firstFY = data.data.data[0].FY
        setAllowedCompareToYears(currentReportPeriod - (+firstFY))
      }
    })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => setShowClimateChangeModal(true)}
        changeReportPeriodCallBack={(value: number) => refreshData(value)}
      />
      {
        loading
          ? <p>Loading...</p>
          : <RegionDataTable tableData={chartData.filter((data: any) => (+data.FY >= reportPeriod - compareToYears))}
            dataKeys={dataKeys}
            unitCellContent={<p className="opacity-40">tCO2e</p>}
          />
      }
    </>
  )
})

export default SummeryFEScopeSection
