import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

// third-party
import ReactECharts from "echarts-for-react";
import { observe } from "mobx";
import { observer } from "mobx-react-lite";

const DonutGraph = observer(({
  series = [], height = "250px", showLabel = true
}: {
  series: any[], height?: string, showLabel?: boolean
}) => {

  const [chartRef, setChartRef] = useState(null);

  // const sum = series.reduce((a, b) => a + b.value, 0);

  const option = {
    // title: {
    //   // text: `${sum.toLocaleString()}`,
    //   text: `500`,
    //   textStyle: { fontSize: 15 },
    //   // subtext: subtitle,
    //   left: "center",
    //   top: "center",
    // },
    // tooltip: { trigger: 'item' },
    // legend: {
    //   show: allData.length !== 0,
    //   bottom: '2%',
    //   left: 'center'
    // },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["80%", "90%"],
        itemStyle: {
          normal: {
            label: {
              show: showLabel,
              position: "inner",
              // formatter: (params: any) => {
              //   // console.log("PRINTTTT", params);
              //   // return params.data.blur ? `{blurred|${params.value}}` : `${params.name}\n${params.value}`;
              //   return `${params.name}\n${params.value.toLocaleString()}`;
              // },
              rich: {
                blurred: {
                  color: "#ffffff00",
                  opacity: 1,
                  textBorderColor: "#000000",
                  textBorderWidth: 0.2,
                  fontSize: 10,
                  textShadowBlur: 4,
                  textShadowColor: "#000000",
                },
              },
            },
            labelLine: { show: true },
          },
        },
        // color: apexcharPalette,
        avoidLabelOverlap: true,
        label: {
          // show: true,
          position: "inside"
        },
        emphasis: {
          label: {
            show: true,
            formatter: (params: any) => {
              return `${params.name}\n${params.value.toLocaleString()}`;
            },
            // fontSize: 20,
            // fontWeight: "bold",
          },
        },
        // labelLine: { show: true },
        data: series
        // data: series.map((item) => ({
        //   ...item,
        //   itemStyle: clickedOption && item.name === clickedOption
        //     ? {
        //       // color: "red",
        //       opacity: 1,
        //       borderColor: '#fff',
        //       borderWidth: 2,
        //       shadowBlur: 10,
        //       shadowOffsetX: 0,
        //       shadowColor: 'rgba(0, 0, 0, 0.5)'
        //     }
        //     : {
        //       opacity: clickedOption && item.name !== clickedOption ? 0.5 : 1,
        //     }
        // })),
      },
    ],
  };

  // useEffect(() => {
  //   if (!chartRef) return;

  //   chartRef.on("click", onClickPieItem);
  // }, [chartRef]);

  // useEffect(() => {
  //   // THIS FUNCTION REPLACE THE CHART ONCLICK FUNCTION, WHENEVER THAT WAS UPDATED.
  //   const chart = chartRef;
  //   if (!chart) return;
  //   chart.off("click", chart._$handlers.click[0].h);
  //   chart.on("click", onClickPieItem);
  // }, [onClickPieItem]);

  return (
    <Box className="w-full flex flex-col">
      <ReactECharts
        option={option}
        style={{ width: "100%", height: height }}
        className="flex-1"
      // onChartReady={(chart) => {
      //   setChartRef(chart);
      //   chart.on("click", onClickPieItem);
      // }}
      />
    </Box >
  );
})

export default DonutGraph;
