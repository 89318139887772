import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { lerpColor } from "../../utils/colour";


interface Data {
  benchmark: number,
  target: number,
  value: number,
}

interface ScopeChartData {
  scope1: Data,
  scope2: Data,
  scope3: Data,
  total: Data,
  unit: string,
}


const ScopeCompareBarChart = ({ data }: { data: ScopeChartData }) => {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const info = theme.palette.info.light;

  const [series, setSeries] = useState<ApexOptions["series"]>([
    {
      name: 'value',
      data: [0, 0, 0, 0],
      color: "#00aeff"
    },
    {
      name: 'Benchmark',
      data: [0, 0, 0, 0],
      color: "#ffe3a3"
    },
    {
      name: 'Target',
      data: [0, 0, 0, 0],
      color: "#bfecff"
    },
  ]);

  useEffect(() => {
    if (!data) return;
    if (!data.scope1) return;

    setSeries([
      {
        name: 'value',
        data: [data.scope1.value, data.scope2.value, data.scope3.value, data.total.value],
        color: "#00aeff"
      },
      {
        name: 'Benchmark',
        data: [data.scope1.benchmark, data.scope2.benchmark, data.scope3.benchmark, data.total.benchmark],
        color: "#ffe3a3"
      },
      {
        name: 'Target',
        data: [data.scope1.target, data.scope2.target, data.scope3.target, data.total.target],
        color: "#bfecff"
      },
    ]);

    setOptions(options => ({
      ...options,
      yaxis: {
        ...options.yaxis,
        title: {
          text: data && data.unit,
        }
      },
    }))
  }, [data])

  const [options, setOptions] = useState<ReactApexChart['props']>({
    chart: {
      type: 'bar',
      height: 350,
      // stacked: true,
    },
    // stroke: {
    //   width: 1,
    //   colors: ['red']
    // },
    dataLabels: {
      formatter: (val: number) => {
        // return val / 1000 + 'K'
        return +val.toFixed(6)
      },
    },
    xaxis: {
      categories: [
        "Scope 1 avg",
        "Scope 2 avg",
        "Scope 3 avg",
        "Total avg",
      ]
    },
    fill: {
      opacity: 1
    },
    yaxis: {
      labels: {
        formatter: (val: number) => {
          return +val.toFixed(6)
        }
      },
      title: {
        text: data && data.unit,
      }
    },
    style: {
      colors: ['#80c7fd', '#008FFB', '#80f1cb', '#00E396']
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left'
    },
    annotations: {
      points: [
        // {
        //   seriesIndex: 0,
        //   x: "Scope 1 avg",
        //   y: data.scope1.value,
        //   label: {
        //     borderColor: '#333',
        //     style: {
        //       fontSize: '16px',
        //       color: '#333',
        //       background: '#ffe3a3',
        //     },
        //     text: '↓ 3.25%'
        //   }
        // },
        // {
        //   seriesIndex: 0,
        //   x: "Scope 2 avg",
        //   y: 0.1,
        //   label: {
        //     borderColor: '#333',
        //     style: {
        //       fontSize: '16px',
        //       color: '#333',
        //       background: '#ffe3a3',
        //     },
        //     text: '↓ 3.25%'
        //   }
        // },
        // {
        //   seriesIndex: 2,
        //   y: data.scope2.value,
        //   x: "Scope 1 avg",
        //   label: {
        //     borderColor: '#a9e5ff',
        //     style: {
        //       fontSize: '16px',
        //       color: '#333',
        //       background: '#e5ffdd',
        //     },
        //     text: '-'
        //   }
        // },
        // {
        //   seriesIndex: 2,
        //   x: "Scope 2 avg",
        //   y: 0.1,
        //   label: {
        //     borderColor: '#a9e5ff',
        //     style: {
        //       fontSize: '16px',
        //       color: '#333',
        //       background: '#e5ffdd',
        //     },
        //     text: '-'
        //   }
        // },
      ]
    }
  });

  // useEffect(() => {
  //   setOptions((prevState) => ({
  //     ...prevState,
  //     colors: [info],
  //     xaxis: {
  //       labels: {
  //         style: {
  //           colors: [secondary, secondary, secondary, secondary, secondary, secondary, secondary]
  //         }
  //       }
  //     },
  //     tooltip: {
  //       theme: 'light'
  //     }
  //   }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [primary, info, secondary]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type='bar' height={400} />
    </div>
  );
};

export default ScopeCompareBarChart;
