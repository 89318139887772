import React, { useContext, useState } from 'react';
import '../App.css';
import { Autocomplete, Avatar, Box, Chip, CircularProgress, Collapse, Divider, drawerClasses, FormControl, IconButton, InputBase, InputLabel, ListItemIcon, Menu, MenuItem, Select, Skeleton, Step, StepButton, StepLabel, Stepper, styled, SvgIconTypeMap, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import Logo from './Wollemai_Logomark.svg';
import SortIcon from '@mui/icons-material/Sort';
import GridViewIcon from '@mui/icons-material/GridView';
import PeopleIcon from '@mui/icons-material/People';
import GiteIcon from '@mui/icons-material/Gite';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { HtmlTooltip } from '../components/htmlToolTip';
import { FilterContext } from '../context/filter-bar-context';
import { SearchType } from '../utils/options';
import { observer } from 'mobx-react-lite';

const CriteriaHeader = observer(({ breadCrumb }: { breadCrumb: string }) => {
  const { selectedSectors, selectedRegions, searchType } = useContext(FilterContext);

  const getShortenedRegions = () => {
    return selectedRegions.slice(0, 2).join(", ") + (selectedRegions.length > 2 ? ` and ${selectedRegions.length - 2} more` : "");
  }

  const getShortenedSector = () => {
    return selectedSectors.slice(0, 2).join(", ") + (selectedSectors.length > 2 ? ` and ${selectedSectors.length - 2} more` : "");
  }

  return (
    <Box className="flex items-center justify-between w-full text-gray-500 py-1 px-2 rounded-lg"
      style={{ background: 'linear-gradient(90deg, rgba(179,255,235,1) 0%, rgba(236,255,250,1) 49%, rgba(228,249,243,1) 100%)' }}>
      <p>{breadCrumb}</p>
      <Box className="flex items-center gap-8">

        {/* Sector */}
        <HtmlTooltip title={<>
          {
            (selectedSectors && selectedSectors.length > 0)
              ? <>
                <Typography color="inherit">Selected {selectedSectors.length}</Typography>
                {
                  selectedSectors.map((sector, i) => (
                    <p key={`sector_selection_${i}`} className='text-sky-600'>{i + 1}. {sector}</p>
                  ))
                }
              </>
              : <p className='text-sky-600'>All Sectors</p>
          }
        </>}>
          <button className='p-2'>
            Sector <b className='pl-2 text-black'>{(searchType === SearchType.REGION || selectedSectors.length === 0) ? "All" : getShortenedSector()}</b>
          </button>
        </HtmlTooltip>

        <Divider orientation='vertical' flexItem sx={{ my: 1, opacity: 0.8 }} />

        {/* Region */}
        <HtmlTooltip title={<>
          {
            (selectedRegions && selectedRegions.length > 0)
              ? <>
                <Typography color="inherit">Selected {selectedRegions.length}</Typography>
                {
                  selectedRegions.map((region, i) => (
                    <p key={`region_selection_${i}`} className='text-sky-600'>{i + 1}. {region}</p>
                  ))
                }
              </>
              : <p className='text-sky-600'>All Regions</p>
          }
        </>}>
          <button className='p-2'>
            Region <b className='pl-2 text-black'>{(searchType === SearchType.SECTOR || selectedRegions.length === 0) ? "All" : getShortenedRegions()}</b>
          </button>
        </HtmlTooltip>

        <Divider orientation='vertical' flexItem sx={{ my: 1, opacity: 0.8 }} />

        <HtmlTooltip title={<>
          <p className='text-sky-600'>All Owners</p>
          {/* <Typography color="inherit">Selected 1</Typography>
          <p className='text-sky-600'>1. Example</p> */}
        </>}>
          <button className='p-2'>
            Owner <b className='pl-2 text-black'>All</b>
          </button>
        </HtmlTooltip>

        <Divider orientation='vertical' flexItem sx={{ my: 1, opacity: 0.8 }} />

        <HtmlTooltip title={<>
          <p className='text-sky-600'>All Assets</p>
          {/* <Typography color="inherit">Selected 1</Typography>
          <p className='text-sky-600'>1. Example</p> */}
        </>}>
          <button className='p-2'>
            Asset <b className='pl-2 text-black'>All</b>
          </button>
        </HtmlTooltip>
      </Box>
    </Box>
  );
});

export default CriteriaHeader;
