import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import ScopeCompareBarChart from '../../charts/scope-compare-bar-chart'
import SingleBarChart from '../../charts/single-bar-chart'
import CompareFilters from '../../CompareFilters'
import { GenerateFYOptions } from '../../../utils/general'


const SummaryPhysicalWACISection = observer(({ }: {}) => {
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])

  const financialPeriods = GenerateFYOptions();

  const [reportPeriods, setReportPeriods] = useState<number>(financialPeriods[0].value)
  const [compareToYears, setCompareToYears] = useState<number>(3)
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)

  useEffect(() => {
    // console.log("summery ae scope section")

    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: true,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriods,
      entityId: organizationId,
      sectors: [],
      regions: []
    }, "organization", "physical", "emissions_intensity_per_hectare_by_farm").then((data) => {
      setChartData(data.data)
    })
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
      />
      <SingleBarChart data={chartData} />
    </>
  )
})

export default SummaryPhysicalWACISection