import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailLink } from '../api/accountService'; // Correct path for accountService
import SecureStorage from '../services/secureStorage';
import { kOrganizationInfo, kLoggingInEmail } from '../config/constants'; // Correct import paths
import { useLocation } from 'react-router-dom';
import { Typography, CircularProgress } from '@mui/material';
import { SetLocalStorage } from '../utils/cryptography';

const LoginByEmailLink = () => {
    const secureStorage = SecureStorage.getInstance();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search); // Extract query params
    const apiKey = searchParams.get('apiKey'); // Extract apiKey
    const oobCode = searchParams.get('oobCode'); // Extract oobCode (verification code)
    const navigate = useNavigate();

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Loading state for API requests

    useEffect(() => {
        const handleSignInWithEmailLink = async () => {
            try {
                if (!oobCode) {
                    setError('Invalid login link.');
                    return;
                }

                // Get the email from secure storage (or any other storage where the email is saved)
                let currentEmail = secureStorage.getItem(kLoggingInEmail);
                // alert(currentEmail)
                if (!currentEmail) {
                    setError('Missing email record.');
                    return;
                }

                if (!apiKey) {
                    setError('API key is missing.');
                    return;
                }

                // Call the account service to verify the email link
                const response = await signInWithEmailLink({ oobCode, apiKey });

                // Save JWT and organization info in secure storage
                secureStorage.setJwtToken(response.jwtToken);
                SetLocalStorage('accessToken', response.jwtToken);
                secureStorage.setItem(kOrganizationInfo, {
                    organization_id: response.organization_id,
                    organization_type: response.organization_type,
                    organization_name: response.organization_name,
                    is_pro_account: response.organization_type === 'pro',
                });

                // Redirect the user to the homepage/dashboard after successful login
                navigate('/summary/dashboard');
            } catch (error: any) {
                console.error('Error signing in with email link:', error.message);
                setError('Error signing in with email link. Please try again.');
            } finally {
                setLoading(false); // Stop loading after the API call
            }
        };

        handleSignInWithEmailLink(); // Trigger the login verification
    }, [oobCode, apiKey, navigate, secureStorage, location.search]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
            {loading ? (
                <>
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: '20px' }}>
                        Logging you in...
                    </Typography>
                </>
            ) : (
                <Typography variant="h6" color="error">
                    {error || 'Invalid login link.'}
                </Typography>
            )}
        </div>
    );
};

export default LoginByEmailLink;
