import React, { useContext, useEffect, useState } from 'react'
import RegionDataTable, { TableDataKeyType } from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData, getOrganizationLandUseData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import { FormatKeyToLabel, GenerateFYOptions } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'

import luMap from "../../../images/demo_images/land_use_emissions.png"
import { Box } from '@mui/material'


const SummaryLUMapSection = observer(({ }: {}) => {
  const { reportPeriod, setAllowedCompareToYears } = useContext(FilterContext)

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [error, setError] = useState<string>("")

  useEffect(() => {
    setAllowedCompareToYears(0)
    // console.log("summery ae scope section")

    // TODO: CONNECT MAP API

    setLoading(true)
    getOrganizationLandUseData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriod,
      entityId: organizationId,
      sectors: "all",
      regions: "all"
    }, "organization", "landuse", "map").then((data) => {
      console.log(data)
    })
      .catch((err) => {
        console.log(err)
        setError(err.message)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
      />

      {
        loading
          ? <p>Loading...</p>
          : <Box className="flex flex-col justify-center items-center">
            {error && <p className='text-red-500'>Failed to fetch map: {error}</p>}
            <img src={luMap} alt="lu-map" />
          </Box>
      }
    </>
  )
})

export default SummaryLUMapSection
