import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SecureStorage from '../services/secureStorage'; // Secure storage for token handling
import { signInWithEmailPassword } from '../api/accountService'; // Account service for login API
import { GetLocalStorage, SetLocalStorage } from '../utils/cryptography';

interface AuthContextType {
    isAuthenticated: boolean;
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
}

// Custom hook for authentication
export const useAuth = (): AuthContextType => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const navigate = useNavigate();

    // Check authentication on component mount
    useEffect(() => {
        const checkAuth = () => {
            const persistedToken = GetLocalStorage('accessToken');
            const token = SecureStorage.getInstance().getJwtToken() || persistedToken; // Get JWT token from secure storage

            if (token) {
                setIsAuthenticated(true); // If token exists, user is authenticated
            } else {
                setIsAuthenticated(false);
            }
        };

        checkAuth(); // Run on mount
    }, []);

    // Login function
    const login = async (email: string, password: string): Promise<void> => {
        try {
            const response = await signInWithEmailPassword(email, password); // Call login API
            if (response.data && response.data.idToken) {
                SecureStorage.getInstance().setJwtToken(response.data.idToken); // Store token securely in memory
                SetLocalStorage('accessToken', response.data.idToken);
                SecureStorage.getInstance().setItem('organizationId', response.data.organization_id); 
                setIsAuthenticated(true); // Update auth state
                navigate('/dashboard'); // Redirect to dashboard
            } else {
                throw new Error('Invalid login');
            }
        } catch (error) {
            console.error('Login error:', error);
            throw error; // Let the component handle errors (e.g., show a message to the user)
        }
    };

    // Logout function
    const logout = (): void => {
        SecureStorage.getInstance().clearJwtToken(); // Clear the token from memory
        setIsAuthenticated(false); // Update auth state
        navigate('/login'); // Redirect to login page
    };

    return { isAuthenticated, login, logout };
};
