import axios from 'axios';
import SecureStorage from '../services/secureStorage';
import { kLoggingInEmail } from '../config/constants'

// Define the types for the expected input parameters and return values

interface SignInWithEmailLinkParams {
    apiKey?: string;
    oobCode: string;
}

interface SignInWithEmailLinkResponse {
    organization_id: string;
    organization_name: string;
    organization_type: string;
    jwtToken: string;
    refreshToken: string;
}

// Login using email for email link (passwordless authentication)
export async function triggerSignInWithEmailLink(email: string): Promise<any> {
    try {
        const url = `${process.env.REACT_APP_API_BASEURL}/account/login`;
        const response = await axios.post(url, { email, version: "2" });

        // If login link is successfully sent, store the email into secure storage
        const secureStorage = SecureStorage.getInstance();
        secureStorage.setItem(kLoggingInEmail, email); // Store email in localStorage

        return response.data;
    } catch (err) {
        console.error('Login error:', err);
        throw new Error('Failed to login');
    }
}

// Sign in using email link (for passwordless authentication)
export async function signInWithEmailLink(params: SignInWithEmailLinkParams): Promise<SignInWithEmailLinkResponse> {
    try {
        // Load email from secureStorage (kLoggingInEmail)
        const secureStorage = SecureStorage.getInstance();
        const email = secureStorage.getItem(kLoggingInEmail);

        // Ensure email exists in secureStorage
        if (!email) {
            throw new Error('No email found in secure storage.');
        }

        const apiKey = params.apiKey || process.env.REACT_APP_FIREBASE_API_KEY;
        const { oobCode } = params; // Email is now loaded from secureStorage
        const url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithEmailLink?key=${apiKey}`;

        const response = await axios.post(url, { oobCode, email });

        if (response.data && response.data.idToken && response.data.refreshToken) {
            const jwtToken = response.data.idToken;

            // Fetch organization data
            const organization_info_url = `${process.env.REACT_APP_API_BASEURL}/account/organization_info`;
            const organization_info_response = await axios.get(organization_info_url, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });

            return {
                organization_id: organization_info_response.data.organization_id,
                organization_name: organization_info_response.data.organization_name,
                organization_type: organization_info_response.data.organization_type,
                jwtToken,
                refreshToken: response.data.refreshToken,
            };
        } else {
            throw new Error('Invalid response from the server');
        }
    } catch (err) {
        console.error('Sign in with email link error:', err);
        throw new Error('Failed to sign in with email link');
    }
}

// For demo only - Sign in with email and password (classic authentication)
export async function signInWithEmailPassword(email: string, password: string): Promise<any> {
    try {
        const url = `${process.env.REACT_APP_API_BASEURL}/account/login/classic`;
        const body = {
            email,
            password,
        };
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axios.post(url, body, { headers });
        return response.data;
    } catch (err) {
        console.error('Sign in with email and password error:', err);
        throw new Error('Failed to sign in with email and password');
    }
}
