import React, { useEffect, useRef, useState } from 'react'
import RegionDataTable from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData, getOrganizationLandUseData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import { FormatKeyToLabel, GenerateFYOptions } from '../../../utils/general'
import { Box, Typography } from '@mui/material'
import SunburstChart from '../../charts/sunburst-chart'
import { RandomRGB } from '../../../utils/colour'


const SummaryLULandUseTypeSection = observer(({ }: {}) => {
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>()
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const financialPeriods = GenerateFYOptions();

  const [reportPeriods, setReportPeriods] = useState<number>(financialPeriods[0].value)
  const [compareToYears, setCompareToYears] = useState<number>(3)
  const [unit, setUnit] = useState<string>("")

  const [loading, setLoading] = useState<boolean>(false)

  const colors = ["#0061ff", "#c8c369", "#018c68"]

  useEffect(() => {
    // console.log("summery ae scope section")

    setLoading(true)
    getOrganizationLandUseData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriods,
      entityId: organizationId,
      sectors: "all",
      regions: "all"
    }, "organization", "landuse", "land_use_type").then((data) => {
      const fyData = data.data.data.find((data: any) => +data.FY === reportPeriods)

      if (!fyData) {
        setChartData(null)
        return
      }

      setUnit(fyData.total.unit)

      const allKeys = Object.keys(fyData)
      let processedData: any[] = []

      allKeys
        .filter((key: any) => !["FY", "total"].includes(key))
        .map((key: any, index: number) => {
          let mainColor = index >= colors.length ? RandomRGB() : colors[index];

          let tempData = {
            ...fyData[key],
            name: FormatKeyToLabel(key),
            itemStyle: {
              color: mainColor
            },
            children: fyData[key].children.map((child: any, childIndex: number) => {
              let childNode = {}

              Object.keys(child)
                .filter((childKey: any, index: number) => index === 0)
                .map((childKey: any) => {
                  childNode = {
                    ...child[childKey],
                    name: FormatKeyToLabel(childKey),
                    itemStyle: {
                      color: `${mainColor}99`
                    }
                  };
                })

              return {
                ...childNode,
              }
            })
          }

          processedData.push(tempData)
        })

      setChartData(processedData)
    })
      .finally(() => setLoading(false))

  }, [reportPeriods])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
      />

      {
        loading
          ? <p>Loading...</p>
          : <Box className=" flex justify-center gap-8 h-[550px]">
            <Box className="w-[500px] h-full">
              <SunburstChart data={chartData} />
            </Box>
            <Box className=" flex flex-col justify-center items-start h-fit w-fit bg-gray-200 p-2 rounded-md my-auto">
              {
                chartData && <table className="">
                  <thead>
                    <tr>
                      <th className="p-2 ">Land Use Type</th>
                      <th className="p-2 text-right">{unit || ""}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      chartData && chartData.length > 0 && chartData
                        .map((rowData: any) => (
                          <>
                            <tr>
                              <td className="p-2">
                                <div className="flex items-center gap-2">
                                  {rowData.itemStyle && <div className="w-4 h-4 rounded-sm" style={{ backgroundColor: rowData.itemStyle.color }}></div>}
                                  <span>{rowData.name}</span>
                                </div>
                              </td>
                              <td className="p-2 text-right">{rowData.value.toLocaleString()}</td>
                            </tr>
                            {
                              rowData.children && rowData.children.length > 0 && rowData.children
                                .map((child: any) => (
                                  <tr>
                                    <td className="p-2 pl-6">
                                      <div className="flex items-center gap-2">
                                        {child.itemStyle && <div className="w-4 h-4 rounded-sm" style={{ backgroundColor: child.itemStyle.color }}></div>}
                                        <span>{child.name}</span>
                                      </div>
                                    </td>
                                    <td className="p-2 text-right">{child.value.toLocaleString()}</td>
                                  </tr>
                                ))
                            }
                          </>
                        ))
                    }
                  </tbody>
                </table>
              }
            </Box>
          </Box>
      }
    </>
  )
})

export default SummaryLULandUseTypeSection
