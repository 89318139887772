import React, { useContext, useEffect, useState } from 'react'
import RegionDataTable, { TableDataKeyType } from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { getOrganizationAEData, getOrganizationLandUseData } from '../../../api/explorerService'
import { ClimateScenario } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import { FormatKeyToLabel, GenerateFYOptions } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'

import removalsMap from "../../../images/demo_images/land_use_landusetype.png"
import { Box } from '@mui/material'


const SummaryRemovalsMapSection = observer(({ }: {}) => {
  const { reportPeriod, setAllowedCompareToYears } = useContext(FilterContext)

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setAllowedCompareToYears(0)
    // console.log("summery ae scope section")

    // setLoading(true)
    // getOrganizationLandUseData({
    //   force_refresh: false,
    //   include_trajectory: true,
    //   climateScenario: ClimateScenario.CURRENT,
    //   reportPeriod: "" + reportPeriods,
    //   entityId: organizationId,
    //   sectors: "all",
    //   regions: "all"
    // }, "organization", "landuse", "summary").then((data) => {
    //   setChartData(data.data.data)
    // })
    //   .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { setShowClimateChangeModal(true) }}
      />

      {
        loading
          ? <p>Loading...</p>
          : <Box className="flex justify-center items-center">
            <img src={removalsMap} alt="removals-map" />
          </Box>
      }

      {/* <RegionDataTable
        tableData={chartData.filter((data: any) => +data.FY >= reportPeriod - compareToYears)}
        dataKeys={dataKeys}
        unitCellContent={<>
          <p className="text-left opacity-80">Land Use Type</p>
          {unit && <p className="text-left text-sm opacity-40">{unit}</p>}
        </>}
      /> */}
    </>
  )
})

export default SummaryRemovalsMapSection
