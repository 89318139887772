import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import ReactECharts from "echarts-for-react";

import { lerpColor } from "../../utils/colour";
import zIndex from '@mui/material/styles/zIndex';


enum StackType {
  VALUE = "Value",
  BENCHMARK = "Benchmark",
  TARGET = "Target"
}

interface DataKeyType {
  key: string,
  title: string,
}


const valueColor = { from: "#1d62f4", to: "#002c8c" };
const benchmarkColor = { from: "#ffe3a3", to: "#fece5b" };

const SectorCompareBarChart = ({ data = [], reportPeriods, dataKeys }: { data?: {}[], reportPeriods?: number, dataKeys?: DataKeyType[] }) => {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const info = theme.palette.info.light;

  const getLabelYAxis = (dataKeyIndex: number) => {
    let yAxisValue = 0;

    for (let i = 0; i <= dataKeyIndex; i++) {

      const lastFYData = data[data.length - 1];
      //@ts-ignore
      const stackValue = (lastFYData && lastFYData[dataKeys[i].key] && lastFYData[dataKeys[i].key].value) ? +lastFYData[dataKeys[i].key].value.toFixed(2) : 0;

      if (i === dataKeyIndex) {
        yAxisValue += (stackValue / 2);
      } else {
        yAxisValue += stackValue;
      }
    }
    return yAxisValue
  }

  const getLabelText = (dataKey: DataKeyType) => {

    const lastFYData = data[data.length - 1];
    //@ts-ignore
    const stackValue = (lastFYData && lastFYData[dataKey.key] && +lastFYData[dataKey.key].change) ? +lastFYData[dataKey.key].change.toFixed(2) : 0;

    if (stackValue > 0) return `↑ ${(stackValue || 0) * 100}%`
    if (stackValue < 0) return `↓ ${(stackValue || 0) * 100}%`

    return `-`
  }

  const series: any[] = [];

  const getFYLabelEnd = (reportPeriods: number | null | undefined, fy: number | null | undefined) => {
    if (!reportPeriods) return ""
    if (!fy) return ""

    if (+fy > reportPeriods) return "(Predictions)"
    if (+fy === reportPeriods) return "(Current) (Benchmark)"
    if (+fy < reportPeriods) return "(History)"

    return ""
  }

  if (!data) return <>loading...</>;

  const titles = data.map((item: any) => `${item.FY} ${getFYLabelEnd(reportPeriods, +item.FY)}`)

  dataKeys && dataKeys.forEach((dataKey: DataKeyType, dataKeyIndex: number) => {
    series.push({
      name: dataKey.title,
      type: 'bar',
      stack: StackType.VALUE,
      emphasis: {
        focus: 'self'
      },
      z: 1,
      data: data.map((fyData: any) => (fyData[dataKey.key] && fyData[dataKey.key].value) ? +fyData[dataKey.key].value.toFixed(2) : 0),
      barWidth: 60,
      itemStyle: {
        color: lerpColor(valueColor.from, valueColor.to, dataKeyIndex / dataKeys.length),
      },
      markPoint: {
        data: [
          {
            coord: [data.length - 1, getLabelYAxis(dataKeyIndex)],
            symbol: 'rect',
            symbolOffset: ["-120%", "0"],
            symbolSize: [50, 30],
            itemStyle: {
              color: "white",
              borderColor: "#376ad9",
              borderWidth: 1,
            },
            label: {
              show: true,
              position: 'inside',
              formatter: () => getLabelText(dataKey),
              textStyle: {
                color: "#376ad9",
                fontSize: 14,
                fontWeight: "bold",
              }
            }
          },
        ]
      },
    })

    series.push({
      name: `${dataKey.title} (Benchmark)`,
      type: 'bar',
      stack: StackType.BENCHMARK,
      emphasis: {
        focus: 'self'
      },
      data: data.map((fyData: any) => (reportPeriods === +fyData.FY && fyData[dataKey.key] && fyData[dataKey.key].benchmark) ? +fyData[dataKey.key].benchmark.toFixed(2) : 0),
      barWidth: 60,
      itemStyle: {
        color: lerpColor(benchmarkColor.from, benchmarkColor.to, dataKeyIndex / dataKeys.length),
      }
    })

    series.push({
      name: `${dataKey.title} (Target)`,
      type: 'bar',
      stack: StackType.TARGET,
      emphasis: {
        focus: 'self'
      },
      data: data.map((fyData: any) => (reportPeriods === +fyData.FY && fyData[dataKey.key] && fyData[dataKey.key].benchmark) ? +fyData[dataKey.key].benchmark.toFixed(2) : 0),
      itemStyle: {
        color: 0,
        borderWidth: reportPeriods === data.length - 1 ? 2 : 0,
        borderColor: reportPeriods === data.length - 1 ? "red" : "#fff",
        borderType: "solid",
      },
      barWidth: 60,
    })
  })

  // ADD NET EMISSIONS LINE

  // series.push({
  //   name: `Net Emissions`,
  //   type: 'line',
  //   data: data.map((fyData: any) => +fyData["Net Emissions"].value.toFixed(2)),
  //   itemStyle: {
  //     color: "#1d62f4",
  //     borderWidth: 1,
  //   }
  // })

  series.push({
    name: `Net Emissions`,
    type: 'custom',
    // data: data.map((fyData: any) => +fyData["Net Emissions"].value.toFixed(2)),
    renderItem: (params: any, api: any) => {
      // const { seriesIndex, dataIndex, data } = params;
      var points = [];

      for (var i = 0; i < data.length; i++) {
        // @ts-ignore
        var point = api.coord([titles[i], data[i]['Net Emissions'].value]);
        point[0] -= 50;
        points.push(point);
      }

      var style = api.style({
        stroke: "#376ad9",
        fill: 'none',
      });

      return {
        type: 'polyline',
        shape: {
          points: points
        },
        style: style
      };
    },
    itemStyle: {
      borderWidth: 1,
      z: 60
    },
    data: data
  })


  data.map((fyData: any, i: number) => {
    series.push({
      name: `Net Emissions Mark`,
      type: 'custom',
      renderItem: (params: any, api: any) => {
        // const { seriesIndex, dataIndex, data } = params;
        var points = {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
        };

        let centerPoint = api.coord([titles[i], fyData['Net Emissions'].value])
        centerPoint[0] -= 70;

        points.x1 = centerPoint[0] - 35;
        points.y1 = centerPoint[1];
        points.x2 = centerPoint[0] + 35;
        points.y2 = centerPoint[1];

        var style = api.style({
          stroke: "#13debe",
          fill: 'none',
        });

        return {
          type: 'line',
          shape: {
            ...points
          },
          style: style
        };
      },
      itemStyle: {
        borderWidth: 4,
        z: 50
      },
      data: data
    })
  })

  // ADD REMOVALS BAR

  series.push({
    name: `Removals`,
    stack: StackType.VALUE,
    type: 'bar',
    data: data.map((fyData: any) => +fyData["Removals"].value.toFixed(2)),
    itemStyle: {
      color: "transparent",
      borderWidth: 1,
      borderColor: "#1d62f4",
      borderType: "dashed"
    }
  })

  series.push({
    name: `Removals`,
    stack: StackType.BENCHMARK,
    type: 'bar',
    data: data.map((fyData: any) => reportPeriods === +fyData.FY ? +fyData["Removals"].benchmark.toFixed(2) : 0),
    itemStyle: {
      color: "transparent",
      borderWidth: 1,
      borderColor: "#1d62f4",
      borderType: "dashed"
    }
  })

  series.push({
    name: `Removals`,
    stack: StackType.TARGET,
    type: 'bar',
    data: data.map((fyData: any) => reportPeriods === +fyData.FY ? +fyData["Removals"].target.toFixed(2) : 0),
    itemStyle: {
      color: "transparent",
      borderWidth: 1,
      borderColor: "#1d62f4",
      borderType: "dashed"
    }
  })

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      bottom: 0,
      orient: 'horizontal'
    },
    grid: {
      left: 5,
      right: 0,
      bottom: 40,
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: titles
      }
    ],
    yAxis: [
      {
        type: 'value',
      }
    ],
    series: series
  };

  return (
    <div id="chart">
      <ReactECharts option={option} style={{ width: "100%", height: 400 }} />
    </div>
  );
};

export default SectorCompareBarChart;
