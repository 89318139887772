import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Box, Paper, Avatar, ToggleButton, ToggleButtonGroup } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CustomButton from '../components/Button';
import { useAuth } from '../hooks/useAuth';
import { triggerSignInWithEmailLink } from '../api/accountService';
import SecureStorage from '../services/secureStorage';
import { kLoggingInEmail } from '../config/constants';

// Email validation utility
const validateEmail = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [loginMethod, setLoginMethod] = useState<'email' | 'username'>('email');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null); // Error state for validation and API errors
    const { login } = useAuth(); // Use the useAuth hook
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null); // Clear any previous errors

        try {
            if (loginMethod === 'username') {
                // Username/Password login flow
                if (!username || !password) {
                    setError('Please provide both username and password');
                    setLoading(false);
                    return;
                }

                await login(username, password); // Use useAuth's login method
                navigate('/summary/dashboard'); // Redirect to dashboard
            } else if (loginMethod === 'email') {
                // Email link login flow
                if (!validateEmail(email)) {
                    setError('Please provide a valid email address');
                    setLoading(false);
                    return;
                }

                // Store the email in SecureStorage before triggering the email link
                const secureStorage = SecureStorage.getInstance();
                secureStorage.setItem(kLoggingInEmail, email); // Store the email

                const response = await triggerSignInWithEmailLink(email);
                if (response) {
                    alert('Login link sent to your email');
                } else {
                    setError('Failed to send login link. Please try again.');
                }
            }
        } catch (err) {
            setError('Login failed. Please try again.');
            console.error('Login error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleLoginMethodChange = (event: React.MouseEvent<HTMLElement>, newMethod: 'email' | 'username') => {
        if (newMethod !== null) {
            setLoginMethod(newMethod);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} sx={{ padding: 3, marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>

                {/* Toggle between login methods */}
                {/* <ToggleButtonGroup
                    value={loginMethod}
                    exclusive
                    onChange={handleLoginMethodChange}
                    aria-label="login method"
                    sx={{ marginBottom: 2 }}
                >
                    <ToggleButton value="username" aria-label="username and password">
                        Username & Password
                    </ToggleButton>
                    <ToggleButton value="email" aria-label="email login">
                        Email Login
                    </ToggleButton>
                </ToggleButtonGroup> */}

                {/* Display any error */}
                {error && (
                    <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                        {error}
                    </Typography>
                )}

                {/* Form section */}
                <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
                    {loginMethod === 'username' ? (
                        <>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                disabled={loading}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                disabled={loading}
                            />
                            <CustomButton
                                type="submit"
                                fullWidth
                                label={loading ? 'Logging in...' : 'Login with Username'}
                                disabled={loading}
                                sx={{ mt: 3, mb: 2 }}
                            />
                        </>
                    ) : (
                        <>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={loading}
                            />
                            <CustomButton
                                type="submit"
                                fullWidth
                                label={loading ? 'Sending...' : 'Send Login Link'}
                                disabled={loading}
                                sx={{ mt: 3, mb: 2 }}
                            />
                        </>
                    )}
                </Box>
            </Paper>
        </Container>
    );
};

export default Login;
