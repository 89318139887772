import React from 'react';
import { Typography } from '@mui/material';
import Layout from '../layouts/Layout';
import CriteriaHeader from '../layouts/criteria-header';
import { observer } from 'mobx-react-lite';

const AssetsPage = observer(() => {
  return (
    <Layout onTriggerAfterSearch={(search: string) => { console.log(search) }}>
      <div>
        <CriteriaHeader breadCrumb={"Assets List"} />
        <Typography variant="h4">Assets</Typography>
        <Typography variant="body1">This is your Assets page.</Typography>
      </div>
    </Layout>
  );
})

export default AssetsPage;
