import CryptoJS from 'crypto-js';

const encryptionKey = process.env.LOCAL_STORAGE_ENCRYPTION_KEY || '';

export const SetLocalStorage = (key: string, data: any) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
  // const encryptedData = JSON.stringify(data);
  localStorage.setItem(key, encryptedData);
};

export const GetLocalStorage = (key: string) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, encryptionKey).toString(CryptoJS.enc.Utf8);
    // return JSON.parse(encryptedData);
    return JSON.parse(decryptedData);
  }
  return null;
};