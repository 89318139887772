import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { color } from 'echarts';

const LineChartWithMarkings = () => {
  const theme = useTheme();

  const [series, setSeries] = useState<ApexOptions["series"]>([
    {
      name: "Current Policies",
      data: [5, 16, 50],
      color: theme.palette.primary.main
    },
    {
      name: "Delayed transition",
      data: [10, 35, 41],
      color: "#a6c6ff"
    },
    {
      name: "Net Zero",
      data: [4, 14, 20],
      color: theme.palette.primary.dark
    },
    {
      name: "Benchmark",
      data: [null, null, 60],
      color: "#ffe3a3"
    },
    {
      name: "Target",
      data: [null, null, 90],
      color: "#fff0f0"
    }
  ]);

  const [options, setOptions] = useState<ReactApexChart['props']>({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      // color: theme.palette.primary.main,
      // width: 2,
    },
    title: {
      text: 'ml',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: [2022, 2023, 2024],
    },
    legand: {
      position: 'bottom',
      horizontalAlign: 'left'
    },
    yaxis: {
      title: {
        text: 'tCO2e'
      },
      min: 0,
    },
    markers: {
      size: 10,
      shape: "square",
      strokeColors: "black",
      strokeWidth: 2
    },
    annotations: {
      points: [
        {
          x: 2022,
          y: 5,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '16px',
              color: '#333',
              background: '#ffe3a3',
            },
            text: '↓ 3.25%'
          }
        },
        {
          x: 2023,
          y: 16,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '16px',
              color: '#333',
              background: '#ffe3a3',
            },
            text: '↓ 3.25%'
          }
        },
        {
          x: 2024,
          y: 50,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '16px',
              color: '#333',
              background: '#ffe3a3',
            },
            text: '↓ 3.25%'
          }
        }
      ]
    }
  });

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type='line' height={400} />
    </div>
  );
}

export default LineChartWithMarkings
