import { Box } from "@mui/material"
import { observer } from "mobx-react-lite"
import ReactECharts from 'echarts-for-react';

const SunburstChart = observer(({ data }: { data: any }) => {

  const option = {
    series: {
      type: 'sunburst',
      // emphasis: {
      //     focus: 'ancestor'
      // },
      data: data,
      radius: [0, '90%'],
      label: {
        rotate: 'radial'
      }
    }
  };

  return (
    <ReactECharts option={option} style={{ height: '100%' }} />
  )
})

export default SunburstChart
