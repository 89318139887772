import { ArrowDownward, ArrowForwardIos, KeyboardArrowUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite'
import moment from 'moment';
import React, { useContext, useState } from 'react'
import { FormatKeyToLabel, GetCurrentFY } from '../../utils/general';
import ChangesLabel from '../changes-label';
import { FilterContext } from '../../context/filter-bar-context';

interface ValueType {
  change: number,
  value: number,
  target: number,
  bench: number,
}

interface FYData {
  FY: string
}

export interface TableDataKeyType {
  key: string,
  title: string,
  unit?: string,
}

const RegionDataTable = ({ tableData = [], dataKeys, unitCellContent }: { tableData: FYData[], dataKeys?: TableDataKeyType[], unitCellContent?: any }) => {
  const { reportPeriod, showBenchmark, showTarget } = useContext(FilterContext)

  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const viewedPeriod = reportPeriod || GetCurrentFY()
  // const viewedPeriod = GetCurrentFY()

  const handleToggleRow = (row: number) => {
    setExpandedRows(expandedRows.includes(row) ? expandedRows.filter((row: number) => row !== row) : [...expandedRows, row])
  }

  return (
    <div className='p-4 bg-gray-100 flex gap-4 items-center  text-center'>
      <table className='w-full rounded-md overflow-hidden'>
        <thead>
          <th className={`bg-themeDarkBlue text-white p-2 px-4 max-w-[250px]`}>
            {unitCellContent}
          </th>
          {
            tableData && tableData
              .map((fyData, thIndex) => (
                <th key={`region_${fyData.FY}_${thIndex}`} className={`bg-themeDarkBlue text-white p-2`} style={{ width: thIndex === 0 ? "10%" : `${90 / tableData.length}%` }}>
                  <p className="text-center">{fyData.FY}</p>
                  {
                    +fyData.FY === viewedPeriod
                      ? <Box className="grid" sx={{ gridTemplateColumns: showBenchmark && showTarget ? "repeat(3, 1fr)" : showTarget || showBenchmark ? "repeat(2, 1fr)" : "repeat(1, 1fr)" }}>
                        <p className="text-center text-sm opacity-65">Current</p>
                        {showBenchmark && <p className="text-center text-sm opacity-65">Benchmark</p>}
                        {showTarget && <p className="text-center text-sm opacity-65">Target</p>}
                      </Box>
                      : +fyData.FY < viewedPeriod
                        ? <p className="text-center text-sm opacity-65">History</p>
                        : <p className="text-center text-sm opacity-65">Predictions</p>
                  }
                </th>
              ))
          }
        </thead>

        <tbody>
          {
            tableData && dataKeys && dataKeys.map((dataKey, i: number) => {
              const key: string = dataKey.key;
              const isExpanded = expandedRows.includes(i)

              return (
                <>
                  <tr key={`data_key_${i}_${key}`}>

                    <td
                      className={`text-white text-left p-4 cursor-pointer transition-all duration-100 bg-themeDarkBlue hover:bg-themeDarkBlue/80`}
                      onClick={() => handleToggleRow(i)}
                    >
                      {dataKey.unit && <p className="text-sm opacity-65">{dataKey.unit}</p>}
                      <Box className="flex gap-2 items-center">
                        <p className=' whitespace-nowrap'>{dataKey.title}</p>
                        {
                          // @ts-ignore
                          (tableData[0] && tableData[0][key] && tableData[0][key].children && tableData[0][key].children.length > 0) && <>
                            {
                              isExpanded
                                ? <KeyboardArrowUp sx={{ fontSize: 24, ml: "auto" }} />
                                : <ArrowForwardIos sx={{ fontSize: 16, ml: "auto" }} />
                            }
                          </>
                        }
                      </Box>
                    </td>

                    {
                      tableData && tableData
                        .map((fyData: any, i: number) => {
                          const data: any = fyData[key];
                          if (!data) return <></>;

                          if (+fyData.FY === viewedPeriod) return <td key={`fy_${fyData.FY}_${i}`} className={` p-2 border-b bg-white `}>
                            <CurrentBenchmarkTargetCell value={data.value} benchmark={data.benchmark} target={data.target} changeAmount={data.change} />
                          </td>

                          return (
                            <td key={`fy_${fyData.FY}_${i}`} className={` p-2 border-b bg-white `}>
                              <p>{(+(+data.value).toFixed()).toLocaleString()}</p>
                            </td>
                          )
                        })
                    }
                  </tr>

                  {
                    // @ts-ignore
                    isExpanded && tableData[0] && tableData[0][key] && tableData[0][key].children && tableData[0][key].children.map((child: any, childIndex: number) => {
                      const childName = Object.keys(child)[0];

                      return (
                        <tr key={`child_${i}`}>
                          <td className={`text-white text-left p-4 cursor-pointer transition-all duration-100 bg-themeDarkBlue hover:bg-themeDarkBlue/80`}>
                            <Box className="flex gap-2 items-center pl-4">
                              <p className=' whitespace-nowrap opacity-75'>{FormatKeyToLabel(childName)}</p>
                            </Box>
                          </td>

                          {
                            tableData
                              .map((fyData: any, i: number) => {
                                const data: any = fyData[key];
                                if (!data) return <></>;

                                if (+fyData.FY === viewedPeriod) return <td key={`fy_${fyData.FY}_${i}`} className={` p-2 border-b bg-white `}>
                                  <CurrentBenchmarkTargetCell
                                    value={data.children[childIndex][childName].value}
                                    changeAmount={data.children[childIndex][childName].change}
                                    benchmark={data.children[childIndex][childName].benchmark}
                                    target={data.children[childIndex][childName].target}
                                  />
                                </td>

                                return (
                                  <td key={`fy_${fyData.FY}_${i}`} className={` p-2 border-b bg-white `}>
                                    {
                                      data.children[childIndex][childName] && <p>{(+(+data.children[childIndex][childName].value).toFixed()).toLocaleString()}</p>
                                    }
                                  </td>
                                )
                              })
                          }
                        </tr>
                      )
                    })
                  }
                </>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

const CurrentBenchmarkTargetCell = ({ value, changeAmount, benchmark, target }: { value: number, changeAmount: number, benchmark: number, target: number }) => {
  const { showBenchmark, showTarget } = useContext(FilterContext)

  return (
    <Box className="grid" sx={{ gridTemplateColumns: showBenchmark && showTarget ? "repeat(3, 1fr)" : showTarget || showBenchmark ? "repeat(2, 1fr)" : "repeat(1, 1fr)" }}>
      <Box className="flex flex-col items-center">
        <p>{value ? (+(+value).toFixed()).toLocaleString() : "--"}</p>
        <Box className="text-sm">
          <ChangesLabel changeAmount={changeAmount * 100} />
        </Box>
      </Box>
      {showBenchmark && <p>{benchmark ? (+(+benchmark).toFixed()).toLocaleString() : "--"}</p>}
      {showTarget && <p>{target ? (+(+target).toFixed()).toLocaleString() : "--"}</p>}
    </Box>
  )
}

export default observer(RegionDataTable)