import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../layouts/Layout';
import CriteriaHeader from '../layouts/criteria-header';
import { VmSmartTable, VmTBodyType } from '../components/VmTables';
import { observer } from 'mobx-react-lite';
import FilterSection from '../components/summary/filter-section';
import CompareFilters from '../components/CompareFilters';
import { FilterContext } from '../context/filter-bar-context';
import { GetCurrentFY } from '../utils/general';

const OwnersPage = observer(() => {
  const { reportPeriod, setReportPeriod, setAllowedCompareToYears } = useContext(FilterContext)
  const [openStressTestModal, setOpenStressTestModal] = useState(false)

  useEffect(() => {
    setAllowedCompareToYears(0)
  }, [])

  const FakeData = [
    { No: 1, Customer: "Customer 1", OwnerName: "Owner 1", OwnerType: "Owner Type 1", OwnerID: "Owner ID 1", },
    { No: 1, Customer: "Customer 1", OwnerName: "Owner 1", OwnerType: "Owner Type 1", OwnerID: "Owner ID 1", },
    { No: 1, Customer: "Customer 1", OwnerName: "Owner 1", OwnerType: "Owner Type 1", OwnerID: "Owner ID 1", }
  ]

  return (
    <Layout onTriggerAfterSearch={(search: string) => { console.log(search) }}>
      <Box className='flex flex-col gap-2 w-full'>
        <CriteriaHeader breadCrumb={"Owners List"} />
        <CompareFilters handleClickClimateChange={() => { }} />
        <Box className='w-full overflow-x-auto'>
          <VmSmartTable
            thead={["No.", "Customer", "Owner Name", "Owner Type", "Owner ID", ""]}
            source={FakeData}
            tbody={[
              { type: VmTBodyType.NO, fields: ["id"] },
              { type: VmTBodyType.TEXT, fields: ["Customer"] },
              { type: VmTBodyType.TEXT, fields: ["Owner Name"] },
              { type: VmTBodyType.TEXT, fields: ["Owner Type"] },
              { type: VmTBodyType.TEXT, fields: ["Owner ID"] },
              { type: VmTBodyType.TEXT, fields: ["Customer"] },
            ]}
            fixFirstCol={true}
            enableLoadMore={false} />
        </Box >
      </Box >
    </Layout>
  );
});

export default OwnersPage;
