import moment from 'moment';

// export const GenerateFYOptions = (startYear: number, endYear: number) => {
//     const options = [];
//     for (let i = startYear; i <= endYear; i++) {
//         options.push({ value: i, label: i });
//     }
//     return options;
// };

export const GenerateFYOptions = () => {
  const financialYearOptions = [];
  const currentYear = moment().year();
  const currentMonth = moment().month();
  for (let i = currentMonth + 1 < 7 ? 0 : 1; i <= 3; i++) {
    const startYear = currentYear - i;
    financialYearOptions.push({
      value: startYear,
      label: `${startYear}-${startYear + 1}`
    });
  }
  return financialYearOptions;
};


export const GenerateCompareToYearOptions = (yearAmounts?: number, isFuture?: boolean) => {
  const options = [];

  if (yearAmounts === 0) {
    return []
  }

  for (let i = 1; i <= (yearAmounts || 3); i++) {
    options.push({
      value: i,
      label: `${isFuture ? "Next" : "Previous"} ${i} years`
    });
  }
  return options
}


export const GetCurrentFY = () => {
  const currentYear = moment().year();
  const currentMonth = moment().month();

  return currentMonth + 1 < 7 ? currentYear - 1 : currentYear;
}


export const FormatKeyToLabel = (key: string): string => {
  // Handle special cases first
  if (!key) return "";

  // Split by underscores first, then handle camelCase within each part
  const words = key
    .split('_')
    .map(part =>
      part
        .split(/(?=[A-Z0-9])|(?<=[a-zA-Z])(?=[0-9])/g)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    )
    .join(' ');

  return words;
}

export const IsEmptyStr = (str: any) => {
  return !str || str === "";
}

export const IsValidEmailAddress = (address: any) => {
  return address && /\S+@\S+\.\S+/.test(address);
}

export const IsZero = (str: any) => {
  return !str || +str == 0;
}

export const IsNumeric = (str: any) => {
  return str && /^\d+(\.\d+)?$/.test(str);
}

// Time
export const CNDateTimeFormat = "YYYY-MM-DD HH:mm";
export const CNDateFormat = "YYYY-MM-DD";
export const AUDateTimeFormat = "DD/MM/YYYY hh:mm A";
export const AUTimeFormat = "hh:mm A";
export const AUDDateFormat = "DD/MM/YYYY";
export const AUDTimeFormat = "hh:mm A";
export const AUStrDateTimeFormat = "DD MMM YYYY HH:mm A";
export const AUStrFullDateTimeFormat = "ddd, DD MMM YYYY HH:mm A";
export const AUStrDateFormat = "DD MMM YYYY";
export const AUStrDateTimeNonYearFormat = "DD MMM HH:mm A";

export enum Priority {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
}
